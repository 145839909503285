
export const validateInput = (value) => {
    // Remove any non-numeric characters except for the decimal point
    value = value.replace(/[^0-9.]/g, "");

    // Ensure only one decimal point is allowed
    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1) {
        // If there's more than one decimal point, remove additional ones
        value = value.slice(0, decimalIndex + 1) + value.slice(decimalIndex + 1).replace(/\./g, "");

        // Limit to two digits after the decimal point
        const decimalPart = value.split(".")[1];
        if (decimalPart && decimalPart.length > 2) {
            value = value.slice(0, decimalIndex + 3); // Keep only 2 decimal places
        }
    }

    // Allow unlimited digits before the decimal point
    return value;
};


