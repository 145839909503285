<template>
  <div
    class="flex items-center text-xs"
    :class="(isPending || fetchingNextPage) && 'opacity-50'"
  >
    <div class="flex flex-wrap items-center gap-x-1 gap-y-1.5">
      <button
        :disabled="isPending || fetchingNextPage"
        class="flex h-8 w-8 items-center justify-center rounded border border-GreenWhite"
        :class="currentPage === 1 && 'cursor-not-allowed'"
        @click="$emit('prevPage')"
      >
        <img
          :src="arrowRightIcon"
          alt="arrow left"
          class="rotate-180"
          :class="currentPage === 1 && 'opacity-20'"
        />
      </button>

      <button
        :disabled="isPending || fetchingNextPage"
        v-for="(page, index) in renderPageNumbers"
        :key="index"
        class="flex h-8 w-8 items-center justify-center transition-colors duration-300 hover:text-white"
        :class="{
          'bg-ResolutionBlue text-white': currentPage === page,
          'bg-white text-BalticSea hover:bg-ResolutionBlue hover:text-white':
            currentPage !== page,
          'rounded border border-GreenWhite': page !== '...',
          'text-xl font-semibold': page === '...',
        }"
        @click="page !== '...' && $emit('goToPage', page)"
      >
        {{ page }}
      </button>

      <button
        :disabled="isPending || fetchingNextPage"
        class="flex h-8 w-8 items-center justify-center rounded border border-GreenWhite"
        :class="currentPage === numberOfPages && 'cursor-not-allowed'"
        @click="$emit('nextPage')"
      >
        <img
          :src="arrowRightIcon"
          alt="arrow left"
          :class="currentPage === numberOfPages && 'opacity-20'"
        />
      </button>

      <p class="ml-4 text-IronsideGrey">
        of {{ numberOfPages }} {{ numberOfPages > 1 ? "pages" : "page" }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import arrowRightIcon from "@/assets/icons/arrow-right.svg";

defineEmits(["goToPage", "prevPage", "nextPage"]);

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  numberOfPages: {
    type: Number,
    required: true,
  },
  isPending: {
    type: Boolean,
    required: true,
  },
  fetchingNextPage: {
    type: Boolean,
    default: false,
  },
});

const renderPageNumbers = computed(() => {
  const pageNumbers = [];

  const totalPages = props.numberOfPages;
  const currentPage = props.currentPage;

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Always show first page number
    pageNumbers.push(1);

    // Show dots if there are more than 5 pages and we're not in the first 3 pages
    if (currentPage > 3) {
      pageNumbers.push("...");
    }

    // Show current page number and one before and after
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Show dots if there are more pages
    if (currentPage < totalPages - 3) {
      pageNumbers.push("...");
    }

    // Calculate how to show last two page numbers
    const startPage2 =
      currentPage >= totalPages - 2 ? totalPages : totalPages - 1;

    for (let i = startPage2; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  }

  return pageNumbers;
});
</script>
