<template>
  <ModalWrapper @close="closeModal">
    <div
      class="max-w-[569px] w-full mx-auto flex flex-col gap-4 py-6 px-5 md:p-8 lg:p-10 bg-white rounded-3xl shadow-lg"
    >
      <button @click="closeModal" class="self-end w-6 h-6 cursor-pointer">
        <img :src="Close" alt="close" class="w-full h-full" />
      </button>

      <section class="flex flex-col gap-6 items-center">
        <div class="max-w-[280px] self-center text-center w-full space-y-2">
          <h2 class="w-full text-2xl font-bold text-DarkJungle">
            Are you sure you want to cancel appointment
          </h2>
          <p class="text-sm font-normal text-MistBlue">
            You cannot undo this action after you unlink.
          </p>
        </div>

        <div class="w-full space-y-3">
          <label
            for="reason"
            class="block text-sm font-bold text-DarkJungleGreen"
            >Reason for cancelling</label
          >
          <textarea
            id="reason"
            v-model="cancelReason"
            resize="none"
            rows="3"
            class="w-full p-4 border border-Platinum rounded-lg focus:outline-none focus:none text-base font-normal text-DarkJungleGreen"
            placeholder="I want to check my blood glucose level"
          ></textarea>
          <p v-if="!cancelReason" class="text-red-500 text-sm">
            A reason is required to cancel the appointment.
          </p>
        </div>

        <div class="flex items-end gap-3 justify-center">
          <button
            @click="closeModal"
            class="bg-transparent border border-ResolutionBlue text-ResolutionBlue py-3 px-8 rounded-full text-sm font-semibold"
          >
            No, go back
          </button>
          <button
            @click="confirmCancellation"
            :disabled="!cancelReason"
            class="bg-ResolutionBlue text-white py-3 px-8 rounded-full text-sm font-semibold"
            :class="{ 'opacity-50 cursor-not-allowed': !cancelReason }"
          >
            <span v-if="isPending" class="pl-4">
              <LoadingSpinner />
            </span>
            <span v-else> Yes, Cancel </span>
          </button>
        </div>
      </section>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref } from "vue";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import ModalWrapper from "@/components/main/patient/testCenter/modals/ModalWrapper.vue";
import Close from "@/assets/icons/close.svg";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const queryClient = useQueryClient();
const cancelReason = ref("");

const props = defineProps(["appointment"]);
const emit = defineEmits(["close", "cancel-appointment"]);

const closeModal = () => {
  emit("close");
};

const { "appointment/cancelAppointment": cancelAppointment } = mapActions();

// Use Vue Query's useMutation for the cancel appointment logic
const { mutate: cancelAppointmentMutate, isPending } = useMutation({
  mutationFn: (payload) => cancelAppointment(payload),
  onSuccess: () => {
    // Invalidate queries after success to refresh data
    queryClient.invalidateQueries({ queryKey: ["appointments"] });

    // Push success notification
    push.success("Appointment canceled successfully");

    // Emit the cancel event and close the modal
    emit("cancel-appointment", props.appointment.id);
    closeModal();
  },
  onError: (error) => {
    // Log the error and push error notification
    console.error("Cancellation error:", error);
    push.error("Error canceling appointment.");
  },
});

const confirmCancellation = async () => {
  if (cancelReason.value.trim() === "") {
    push.error("Please provide a reason for cancellation");
    return;
  }

  // Call the mutation function with the appointment ID and reason
  cancelAppointmentMutate({
    id: props.appointment.id,
    reason: cancelReason.value,
  });
};
</script>
