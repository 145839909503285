<template>
  <ModalWrapper :show="Boolean(callData)">
    <div
      class="bg-gradient-to-b from-[#011D7E] from-0% to-[#0234E4] to-90% mx-auto my-44 max-w-[350px] space-y-6 rounded-[38px] p-6 sm:p-8 md:p-12 relative z-50"
    >
      <div style="display: none">
        <audio controls autoplay loop>
          <source :src="ringtone" type="audio/mpeg" />
        </audio>
      </div>
      <div class="space-y-8">
        <div class="flex flex-col items-center justify-center gap-4 text-white">
          <img
            :src="profilePicture"
            class="w-36 h-36 rounded-full object-cover"
            alt=""
          />
          <div class="gap-2">
            <h2 class="text-base font-bold md:text-xl">
              {{ callData.caller }}
            </h2>
            <p class="text-sm text-center">is calling you</p>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button
            @click="handleCloseModal"
            type="button"
            class="h-16 w-16 flex justify-center items-center rounded-full bg-[#EB5545]"
          >
            <img :src="declineIcon" alt="decline icon" />
          </button>
          <div @click="joinMeetingRoom">
            <AcceptCallIcon />
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { mapActions } from "@/hooks/mapStore";
import { useMutation } from "@tanstack/vue-query";
import { push } from "notivue";
import ModalWrapper from "@/components/main/ui/modals/ModalWrapper.vue";
import declineIcon from "@/assets/icons/declined-icon.svg";
import defaultProfilePicture from "@/assets/images/blank-profile-picture.webp";
import ringtone from "@/assets/audio/ring-phone.mp3";
import AcceptCallIcon from "@/components/icons/AcceptCallIcon.vue";

const emit = defineEmits(["close"]);
const store = useStore();

const {
  "appointment/patientJoinsVoiceCall": patientJoinsVoiceCall,
  "appointment/patientJoinsVideoCall": patientJoinsVideoCall,
} = mapActions();

const callData = computed(() => store.getters["socket/callData"]);
const profilePicture = callData.caller_avatar || defaultProfilePicture;
const room_id = ref("");

const { mutate } = useMutation({
  mutationFn: (data) =>
    callData.type === "Voice call"
      ? patientJoinsVoiceCall(data)
      : patientJoinsVideoCall(data),
  onError: (error) => {
    if (error) {
      push.error("Error while trying to join the meeting room");
    }
  },
  onSuccess: (data) => {
    console.log("Data to join", data);

    handleCloseModal();

    window.open(data.call_link, "_blank");
  },
});

const joinMeetingRoom = () => {
  const payload = {
    room_id: room_id.value,
  };

  mutate(payload);
};

const handleCloseModal = () => {
  store.commit("socket/setCallData", null);
};

watch(callData, (newValue) => {
  // console.log("callData changed:", newValue);
  if (newValue) {
    room_id.value = newValue.room_id;
  } 
});
</script>
