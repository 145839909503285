<template>
  <teleport to="body">
    <div
      v-if="visible"
      class="absolute bg-white py-1 rounded-md border border-BlueChalk shadow-lg z-50"
      :style="positionStyles"
      @click.stop
    >
      <div>
        <div
          class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
          @click="$emit('edit')"
        >
          <img
            :src="downloadDarkIcon"
            alt="download icon"
            class="group-hover:hidden"
          />
          <img
            :src="downloadLightIcon"
            alt="download icon"
            class="hidden group-hover:block"
          />
          <p class="text-xs font-semibold">Edit</p>
        </div>

        <div>
          <div
            class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
            @click="$emit('delete')"
          >
            <img
              :src="deleteDarkIcon"
              alt="delete icon"
              class="group-hover:hidden"
            />
            <img
              :src="deleteLightIcon"
              alt="delete icon"
              class="hidden group-hover:block"
            />
            <p class="text-xs font-semibold">
              {{ isDeleting ? "Deleting..." : "Delete" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { computed } from "vue";
import downloadDarkIcon from "@/assets/icons/download-dark.svg";
import downloadLightIcon from "@/assets/icons/download-light.svg";
import deleteDarkIcon from "@/assets/icons/delete-dark.svg";
import deleteLightIcon from "@/assets/icons/delete-light.svg";

const props = defineProps({
  isDeleting: Boolean,
  position: Object,
  visible: Boolean,
});

const positionStyles = computed(() => ({
  top: `${props.position.y}px`,
  left: `${props.position.x}px`,
}));

defineEmits(["close", "edit", "delete"]);
</script>
