<template>
  <ModalWrapper>
    <div
      class="max-w-[900px] w-full p-6 md:p-12 rounded-[48px] bg-white relative"
    >
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          Edit {{ testType }}
        </h3>
        <div class="cursor-pointer" @click="closeEditManualTestModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <!-- Test Input Section -->
      <div
        class="max-w-3xl w-full mx-auto flex flex-col items-center gap-[50px]"
      >
        <div
          v-if="
            testType !== 'Malaria' &&
            testType !== 'Widal Test' &&
            testType !== 'Widal' &&
            testType !== 'Hepatitis B' &&
            testType !== 'Hepatitis_B' &&
            testType !== 'Voluntary Serology' &&
            testType !== 'HIV'
          "
          class="self-center w-full h-[226px] border-2 border-ClearBlue flex justify-center items-center rounded-xl"
        >
          <div
            class="flex gap-3 justify-center items-center"
            :class="{
              'w-[200px]': testType === 'Blood Pressure',
              'w-[154px]': testType !== 'Blood Pressure',
            }"
          >
            <!-- Conditional Inputs for Blood Pressure -->
            <template v-if="testType === 'Blood Pressure'">
              <div class="flex items-end gap-0">
                <Input
                  v-model="systolicPressure"
                  :disabled="isAutoMode"
                  type="text"
                  class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-[60%] bg-transparent border-none outline-none"
                  placeholder="_ _"
                  @input="updatePressure"
                />
                <span class="text-ResolutionBlue text-2xl font-bold">/</span>
                <Input
                  v-model="diastolicPressure"
                  type="text"
                  class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-[60%] bg-transparent border-none outline-none"
                  placeholder="_ _"
                />
              </div>
            </template>

            <!-- Single Input for Other Tests -->
            <template v-else>
              <Input
                v-model="testValue"
                type="text"
                class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-full bg-transparent border-none outline-none"
                :placeholder="String(editingData?.glucose_level) || '__'"
              />
            </template>

            <div class="relative">
              <button
                @click="toggleDropdown"
                class="flex items-center justify-center gap-1 py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
                <img
                  :src="whiteDownArrowIcon"
                  alt="unit dropdown"
                  class="ml-1 w-4 h-4"
                  :class="{ 'rotate-180': isDropdownOpen }"
                />
              </button>
              <!-- Custom dropdown menu -->
              <div
                v-if="isDropdownOpen"
                class="absolute top-full left-0 mt-1 w-full bg-white border border-BlueChalk rounded-lg shadow-lg z-50"
              >
                <button
                  v-for="option in unitOptions"
                  :key="option"
                  @click="selectUnit(option)"
                  class="block w-full text-left px-4 py-2 text-sm text-ResolutionBlue hover:bg-BlueChalk"
                  :class="{ 'bg-BlueChalk': units === option }"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Range Indicator -->
        <div
          v-if="
            testType !== 'ECG' &&
            testType !== 'Malaria' &&
            testType !== 'Widal Test' &&
            testType !== 'Widal' &&
            testType !== 'Weight' &&
            testType !== 'Hepatitis B' &&
            testType !== 'Hepatitis_B' &&
            testType !== 'Voluntary Serology' &&
            testType !== 'HIV'
          "
          class="w-full flex flex-col items-center"
        >
          <!-- Range indicator and markers -->
          <!-- Labels above the range -->
          <div class="w-full flex justify-between text-xs text-MistBlue">
            <span>Low</span>
            <span>Normal</span>
            <span>Average High</span>
            <span>High</span>
          </div>
          <div class="w-full h-4 relative">
            <!-- Color bars -->
            <div
              class="absolute bottom-0 w-full h-2 rounded-full overflow-hidden flex"
            >
              <div class="w-[25%] h-full bg-ResolutionBlue"></div>
              <div class="w-[25%] h-full bg-AsignGreen"></div>
              <div class="w-[25%] h-full bg-OrangePeel"></div>
              <div class="w-[25%] h-full bg-ArtyClickRed"></div>
            </div>
            <!-- Moving Circle -->
            <div
              class="absolute top-1 w-4 h-4 flex items-center justify-center rounded-full bg-white"
              :class="[rangeColors.border]"
              :style="{ left: `${rangeCirclePosition}%` }"
            >
              <div class="w-2 h-2 rounded-full" :class="[rangeColors.bg]"></div>
            </div>

            <!-- Vertical Markers -->
            <div
              class="absolute top-1 left-[25%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
            <div
              class="absolute top-1 left-[50%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
            <div
              class="absolute top-1 left-[75%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
          </div>
        </div>

        <div class="w-full">
          <!-- Reusable Component -->
          <TestTypeFields
            :type="testType"
            :additionalFields="additionalFields"
            :setActiveTab="setActiveTab"
            :activeTab="activeTab"
            :widalTests="widalTests"
            :hepatitisResult="hepatitisResultObj"
            :hivResult="hivResultObj"
          />
        </div>

        <!-- Date and Time Selection -->
        <div class="w-full flex items-center gap-3">
          <!-- Date Picker -->
          <div class="w-full cursor-pointer relative" @click="openDatePicker">
            <input
              type="date"
              ref="datePicker"
              v-model="selectedDate"
              :max="maxDate()"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer"
            />
            <div
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span>{{ formattedDate || "Choose date" }}</span>
              <img
                :src="calendarGrayIcon"
                alt="calendar icon"
                class="ml-2 w-5 h-5"
              />
            </div>
          </div>

          <!-- Time Picker -->
          <div class="w-full cursor-pointer relative" @click="openTimePicker">
            <input
              type="time"
              ref="timePicker"
              v-model="selectedTime"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer z-10"
            />
            <div
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span>{{ formattedTime || "Choose time" }}</span>
              <img
                :src="clockIcon"
                alt="clock icon"
                class="ml-2 w-5 h-5 pointer-events-none"
              />
            </div>
          </div>
        </div>

        <!-- Submit and Cancel Section -->
        <div class="flex justify-center items-center gap-4 mt-8">
          <BaseButton
            @click="closeEditManualTestModal"
            class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
          >
            Cancel
          </BaseButton>
          <BaseButton
            @click="handleSubmit"
            :disabled="isPending || !isFormValid"
            :class="[
              'px-[52px] py-3 text-white rounded-full text-sm font-semibold',
              isPending || !isFormValid
                ? 'bg-[#E0E0E0] cursor-not-allowed'
                : 'bg-ResolutionBlue cursor-pointer',
            ]"
          >
            <span v-if="isPending">
              <LoadingSpinner />
            </span>
            <span v-else> Save </span>
          </BaseButton>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref, computed, inject, watch, reactive, onMounted } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { push } from "notivue";
import { maxDate } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import ModalWrapper from "./ModalWrapper.vue";
import Input from "@/components/main/ui/Input.vue";
import TestTypeFields from "../TestTypeFields.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import whiteDownArrowIcon from "@/assets/icons/white-down-arrow.svg";
import calendarGrayIcon from "@/assets/icons/calendar-gray-icon.svg";
import clockIcon from "@/assets/icons/clock.svg";

const {
  "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords,
  "test/editManualTest": editManualTest,
} = mapActions();

// Props received for editing
const props = defineProps({
  testType: String,
  patientId: String,
  testTypeId: [String, Object],
  editingData: Object, // Data passed for editing
});

const queryClient = useQueryClient();

const testValue = ref(String(props.editingData?.glucose_level || ""));
const selectedDate = ref(""); // Raw date value
const selectedTime = ref(""); // Raw time value
const formattedDate = ref(""); // Formatted date for display
const formattedTime = ref(""); // Formatted time for display
const additionalFields = reactive({
  beforeMeal: false,
  fasting: false,
  pulseRate: "",
  padDetected: null,
  description: "",
  fev1: "",
  pef: "",
  analysis: "",
  malaria_note: "",
});
const systolicPressure = ref("");
const diastolicPressure = ref("");
const widalTests = reactive([]);
const hepatitisResultObj = reactive({ value: "" });
const hivResultObj = reactive({ value: "" });
const activeTab = ref("beforeMeal");
const units = ref("mg/dL");
const unitOptions = ["mg/dL", "mmol/L"];
const isDropdownOpen = ref(false);

// References for the hidden input elements
const datePicker = ref(null);
const timePicker = ref(null);

const rangeCirclePosition = computed(() => {
  if (props.testType === "Blood Pressure") {
    // Custom logic for Blood Pressure ranges
    const systolic = parseInt(systolicPressure.value, 10) || 0;

    if (systolic < 90) return 10; // Low
    if (systolic >= 90 && systolic <= 120) return 30; // Normal
    if (systolic > 120 && systolic <= 140) return 60; // Average High
    return 90; // High
  } else {
    // General logic for other test types
    const value = parseInt(testValue.value, 10) || 0;
    if (value < 50) return 10; // Low
    if (value >= 50 && value <= 100) return 30; // Normal
    if (value > 100 && value <= 150) return 60; // Average High
    return 90; // High
  }
});

const rangeStatus = computed(() => {
  const position = rangeCirclePosition.value;

  if (position <= 25) return "Low";
  if (position > 25 && position <= 50) return "Normal";
  if (position > 50 && position <= 75) return "Average High";
  return "High";
});

const rangeColors = computed(() => {
  const position = rangeCirclePosition.value;

  // Define colors for each range
  if (position <= 25) {
    return {
      bg: "bg-ResolutionBlue",
      border: "border-2 border-ResolutionBlue",
    }; // Low
  } else if (position > 25 && position <= 50) {
    return { bg: "bg-AsignGreen", border: "border-2 border-AsignGreen" }; // Normal
  } else if (position > 50 && position <= 75) {
    return { bg: "bg-OrangePeel", border: "border-2 border-OrangePeel" }; // Average High
  } else {
    return { bg: "bg-ArtyClickRed", border: "border-2 border-ArtyClickRed" }; // High
  }
});

const closeEditManualTestModal = inject("closeEditManualTestModal");

// Function to switch between "beforeMeal" and "afterMeal"
const setActiveTab = (tab) => {
  activeTab.value = tab;
};

// Handle date and time changes
const handleDateChange = () => {
  if (selectedDate.value) {
    const date = new Date(selectedDate.value);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    formattedDate.value = `${day}-${month}-${year}`;
  }
};

const handleTimeChange = () => {
  if (selectedTime.value) {
    const time = new Date(`1970-01-01T${selectedTime.value}:00`);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    formattedTime.value = `${hours}:${minutes}:${seconds}`;
  }
};

// Function to populate the form with existing data
const populateEditingData = () => {
  const data = props.editingData || {};

  // Reset all fields first
  testValue.value = "";
  units.value = "";
  selectedDate.value = "";
  selectedTime.value = "";
  Object.assign(additionalFields, {
    beforeMeal: false,
    fasting: false,
    pulseRate: "",
    padDetected: null,
    description: "",
    fev1: "",
    pef: "",
    analysis: "",
    malaria_note: "",
  });

  widalTests.value = [];
  hepatitisResultObj.value = {};
  hivResultObj.value = {};

  if (data) {
    // Common fields for all test types
    if (data.check_time) {
      const dateTime = new Date(data.check_time);
      selectedDate.value = dateTime.toISOString().split("T")[0];
      selectedTime.value = dateTime.toTimeString().slice(0, 5);
    }

    handleDateChange();
    handleTimeChange();

    // Test type specific fields
    switch (props.testType) {
      case "Blood Glucose":
        testValue.value = String(data.glucose_level || "");
        units.value = data.units || "";
        additionalFields.beforeMeal = data.before_meal;
        additionalFields.fasting = data.fasting;
        break;

      case "Heart Rate":
        testValue.value = String(data.oxygen_saturation || "");
        units.value = data.oxygen_saturation_unit || "";
        additionalFields.pulseRate = data.pulse_rate;
        additionalFields.perfusionIndex = data.perfusion_index;
        break;

      case "Blood Pressure":
        systolicPressure.value = String(data.systolic || "");
        diastolicPressure.value = String(data.diastolic || "");
        units.value = data.units || "";
        additionalFields.pulseRate = data.pulse_rate;
        additionalFields.padDetected = data.pad_detected ? 1 : 0;
        break;

      case "Weight":
        testValue.value = String(data.weight || "");
        units.value = data.units || "";
        break;

      case "Temperature":
        testValue.value = String(data.temperature || "");
        units.value = data.units || "";
        additionalFields.status = data.status;
        additionalFields.description = data.description;
        break;

      case "Lungs Capacity":
        testValue.value = String(data.pef || "");
        units.value = data.pef_unit || "";
        additionalFields.fev1 = data.fev1;
        break;

      case "ECG":
        testValue.value = String(data.heart_rate || "");
        additionalFields.analysis = data.analysis;
        units.value = data.units || "";
        break;

      case "Malaria":
        additionalFields.malaria_note = data.malaria_parasite_note;
        break;

      case "Widal":
        widalTests.value = widalTests.splice(
          0,
          widalTests.length,
          ...(data.widal_results?.map((result) => ({
            testName: result.name,
            testResult: result.value,
          })) || [])
        );
        break;

      case "Hepatitis_B":
        hepatitisResultObj.value =
          data.hepatitis_b === "Positive"
            ? "HEP B+"
            : data.hepatitis_b === "Negative"
            ? "HEP B-"
            : "";
        break;

      case "HIV":
        hivResultObj.value = { value: data.hiv || "" };
        hivResultObj.value =
          data.hiv === "Positive"
            ? "HIV +"
            : data.hiv === "Negative"
            ? "HIV -"
            : "";
        break;
    }
  }
};

watch(
  () => props.editingData,
  (newData) => {
    if (!newData) {
      selectedDate.value = "";
      selectedTime.value = "";
      testValue.value = "";
      return;
    }

    populateEditingData();
  },
  { immediate: true }
);

// Form validation
const isFormValid = computed(() => {
  if (!formattedDate.value || !formattedTime.value) return false;

  if (props.selectedTestType === "Blood Pressure") {
    return systolicPressure.value && diastolicPressure.value;
  }

  if (["Temperature", "Weight"].includes(props.selectedTestType)) {
    return testValue.value;
  }

  if (props.selectedTestType === "Glucose") {
    return (
      testValue.value &&
      (activeTab.value === "fasting" ||
        (activeTab.value !== "fasting" &&
          additionalFields.beforeOrAfterMeals !== undefined))
    );
  }
  if (props.selectedTestType === "Lungs capacity") {
    return testValue.value && additionalFields.fev1;
  }

  if (props.selectedTestType === "ECG") {
    return testValue.value && additionalFields.analysis;
  }

  if (props.selectedTestType === "Malaria") {
    return additionalFields.malaria_note;
  }

  if (props.selectedTestType === "Spo2") {
    return additionalFields.pulseRate && additionalFields.perfusionIndex;
  }

  if (props.selectedTestType === "Spo2") {
    const pulseRate = additionalFields.pulseRate;
    if (pulseRate < 30 || pulseRate > 220) {
      push.error("Pulse Rate must be between 30 and 220.");
      return;
    }
  }

  if (props.selectedTestType === "Widal Test") {
    return widalTests.value.every((test) => test.testResult);
  }

  if (props.selectedTestType === "Hepatitis B") {
    return (
      hepatitisResultObj.value === "HEP B+" ||
      hepatitisResultObj.value === "HEP B-"
    );
  }

  if (props.selectedTestType === "Voluntary Serology") {
    return hivResultObj.value === "HIV +" || hivResultObj.value === "HIV -";
  }

  return true;
});

// Mutation for editing manual test
const { mutate: editManualTestMutation, isPending } = useMutation({
  mutationFn: async ({ testType, testTypeId, payload }) => {
    return await editManualTest({
      testType,
      testTypeId,
      payload,
    });
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ["bloodGlucoseRecords"] });
    push.success("Test result edited successfully!");
    closeEditManualTestModal();

    // Refetch the blood glucose records after a successful edit
    fetchBloodGlucoseRecords({
      test_type: "glucose",
    });
  },
  onError: (error) => {
    const errorMessage =
      error.response?.data?.message || "Error editing the test result.";
    push.error(errorMessage);
  },
});

// In handleSubmit function
const handleSubmit = () => {
  if (!isFormValid.value) {
    push.error("Please fill all required fields");
    return;
  }

  const basePayload = {
    Check_Time: `${formattedDate.value} ${formattedTime.value}`,
    testValue: testValue.value,
    units: units.value,
    additionalFields: { ...additionalFields },
    systolicPressure: systolicPressure.value,
    diastolicPressure: diastolicPressure.value,
    widalTests: [...widalTests],
    hepatitisResult: hepatitisResultObj.value,
    hivResult: hivResultObj.value,
    status: rangeStatus.value,
  };

  // Add test type specific data
  switch (props.testType) {
    case "Blood Glucose":
      basePayload.beforeOrAfterMeals =
        activeTab.value === "beforeMeal"
          ? 0
          : activeTab.value === "afterMeal"
          ? 1
          : 0;
      break;

    // case "Blood Pressure":
    //   basePayload.additionalFields.status = rangeStatus.value;
    //   break;
  }

  editManualTestMutation({
    testType: props.testType,
    testTypeId: props.testTypeId,
    payload: basePayload,
  });
};

// Function to toggle dropdown
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const openDatePicker = () => {
  if (datePicker.value) {
    datePicker.value.click();
  }
};

const openTimePicker = () => {
  if (timePicker.value) {
    timePicker.value.click();
  }
};

// Function to select a unit
const selectUnit = (newUnit) => {
  units.value = newUnit;
  isDropdownOpen.value = false;
};

// Watch for changes in selectedDate and selectedTime
watch(selectedDate, handleDateChange);
watch(selectedTime, handleTimeChange);
</script>

<style scoped>
.input-with-dashes::placeholder {
  text-align: center;
  color: #011d7e;
}
</style>
