import { createRouter, createWebHistory } from "vue-router";

// Authentication views
import SignInView from "../views/auth/SignInView.vue";
import SignUpView from "../views/auth/SignUpView.vue";
import ResetPasswordView from "../views/auth/resetPassword/ResetPasswordView.vue";
import ResetEmailSentView from "../views/auth/resetPassword/ResetEmailSentView.vue";
import CreateNewPasswordView from "../views/auth/resetPassword/CreateNewPasswordView.vue";
import PasswordResetConfirmedView from "../views/auth/resetPassword/PasswordResetConfirmedView.vue";
import EmailVerifiedView from "../views/auth/resetPassword/EmailVerifiedView.vue";
import VerifyEmailView from "../views/auth/resetPassword/VerifyEmailView.vue";
import EmailExpiredView from "../views/auth/resetPassword/EmailExpiredView.vue";

import MainView from "../views/main/MainView.vue";

// Patient views
import PatientDashboardView from "../views/main/patient/PatientDashboardView.vue";
import AppointmentView from "../views/main/patient/AppointmentView.vue";
import OnlineAppointmentView from "../views/main/patient/OnlineAppointmentView.vue";
import PatientSettingsView from "../views/main/patient/PatientSettingsView.vue";
import NotificationView from "../views/main/patient/NotificationView.vue";
import AccountInformationView from "../views/main/patient/AccountInformationView.vue";
import PatientVitalDetailsView from "../views/main/admin/PatientVitalDetailsView.vue";
import AccountLinkingView from "../views/main/patient/AccountLinkingView.vue";
import LinkedAccountVitalsView from "../views/main/patient/LinkedAccountVitalsView.vue";
import LinkedWellnessProfileView from "@/views/main/patient/LinkedWellnessProfileView.vue";
import DeviceInformationView from "../views/main/patient/DeviceInformationView.vue";
import SecurityAndPreferenceView from "../views/main/patient/SecurityAndPreferenceView.vue";
import ReportAndStatisticsView from "../views/main/patient/ReportAndStatisticsView.vue";
import NotificationsSettingsView from "../views/main/patient/NotificationsSettingsView.vue";
import BloodGlucoseView from "../views/main/patient/BloodGlucoseView.vue";

//Admin views
import AdminDashboardView from "../views/main/admin/AdminDashboardView.vue";
import AddBlogPostView from "../views/main/admin/blog/AddBlogPostView.vue";
import BlogPostsView from "../views/main/admin/blog/BlogPostsView.vue";
import AdminSettingsView from "../views/main/admin/AdminSettingsView.vue";

import PageNotFoundView from "../views/PageNotFoundView.vue";
import store from "@/store";
import checkAuthentication from "@/api/checkAuthentication";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/auth/sign-in",
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/auth",
    name: "signin",
    redirect: "/auth/sign-in",
    meta: { toTop: true, smoothScroll: true },
    beforeEnter: async (to, from, next) => {
      const user = await store.dispatch("auth/isAuthenticated");
      let admin = false;
      if (user) {
        admin = user.user.roles[0] === "admin";
      }

      if (user) {
        if (admin) {
          next("/admin/dashboard");
        } else {
          next("/patient/dashboard");
        }
      } else {
        next();
      }
    },
    children: [
      {
        path: "sign-in",
        name: "signin",
        component: SignInView,
        meta: { toTop: true, smoothScroll: true },
      },
      {
        path: "sign-up",
        name: "signup",
        component: SignUpView,
        meta: { toTop: true, smoothScroll: true },
      },
    ],
  },
  {
    path: "/auth/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/auth/reset-successful",
    name: "reset-email-sent",
    component: ResetEmailSentView,
    meta: { toTop: true, smoothScroll: true },
    beforeEnter: (to, _, next) => {
      if (!to.query.email) {
        next("/auth/email-not-found");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/create-new-password",
    name: "create-new-password",
    component: CreateNewPasswordView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/auth/verify-email",
    name: "verify-email",
    component: VerifyEmailView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/auth/confirmation-ready",
    name: "email-verified",
    component: EmailVerifiedView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/auth/email-expired",
    name: "email-expired",
    component: EmailExpiredView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/auth/password-reset-confirmed",
    name: "password-reset-confirmed",
    component: PasswordResetConfirmedView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/patient",
    name: "patient",
    redirect: "/patient/dashboard",
    component: MainView,
    meta: { requiresAuth: true, toTop: true, smoothScroll: true },
    children: [
      {
        path: "dashboard",
        name: "patient_dashboard",
        component: PatientDashboardView,
        meta: {
          parent: false,
          subHeaderName: "Dashboard",
        },
      },
      {
        path: "appointment",
        name: "patient_appointment",
        component: AppointmentView,
        meta: {
          parent: false,
          subHeaderName: "Appointment",
        },
      },
      {
        path: "online-appointment",
        name: "online_appointment",
        component: OnlineAppointmentView,
        meta: {
          parent: false,
          subHeaderName: "Online Appointment",
        },
      },
      {
        path: "settings",
        name: "patient_setting",
        component: PatientSettingsView,
        meta: {
          parent: false,
          subHeaderName: "Settings",
        },
      },
      {
        path: "notifications",
        name: "notification",
        component: NotificationView,
        meta: {
          parent: false,
          subHeaderName: "Notifications",
        },
      },
      {
        path: "settings/account-information",
        name: "patient_account_information",
        component: AccountInformationView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Account Information"],
        },
      },
      {
        path: "settings/account-linking",
        name: "patient_account_linking",
        component: AccountLinkingView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Account Linking"],
        },
      },
      {
        path: "settings/account-linking/patient/:id",
        name: "patient_linked_account_vitals",
        component: LinkedAccountVitalsView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Account Linking", "Linked Account"],
        },
      },
      {
        path: "settings/account-linking/center/:id",
        name: "patient_linked_wellness_center",
        component: LinkedWellnessProfileView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Account Linking", "Linked Account"],
        },
      },
      {
        path: "settings/device-information",
        name: "patient_setting_device_information",
        component: DeviceInformationView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Devices"],
        },
      },
      {
        path: "settings/security-and-preferences",
        name: "patient_setting_security_and_preferences",
        component: SecurityAndPreferenceView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Security and Preferences"],
        },
      },
      {
        path: "settings/report-and-statictics",
        name: "setting_report_and_statistics",
        component: ReportAndStatisticsView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Diagnostic Imaging Hub"],
        },
      },
      {
        path: "settings/notifications",
        name: "setting_notifications",
        component: NotificationsSettingsView,
        meta: {
          parent: true,
          subHeaderName: ["Settings", "Notifications"],
        },
      },
      {
        path: "blood-glucose",
        name: "patient_bloodglucose",
        component: BloodGlucoseView,
        meta: {
          parent: false,
          subHeaderName: "Blood Glucose",
        },
        // Allow query for testType
        props: (route) => ({ testTypeQuery: route.query.testType }),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/dashboard",
    component: MainView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      toTop: true,
      smoothScroll: true,
    },
    children: [
      {
        path: "dashboard",
        name: "admin_dashboard",
        component: AdminDashboardView,
        meta: {
          parent: false,
          subHeaderName: "Admin Dashboard",
        },
      },
      {
        path: "vital-details/:full_name",
        name: "patient_vital_details",
        component: PatientVitalDetailsView,
        meta: {
          parent: true,
          subHeaderName: ["Admin Dashboard", "Patient"],
        },
      },
      {
        path: "add-blog-post",
        name: "admin_add_blog_post",
        component: AddBlogPostView,
        meta: {
          parent: true,
          subHeaderName: ["Admin Dashboard", "Add Blog"],
        },
      },
      {
        path: "blog-posts",
        name: "blog_posts",
        component: BlogPostsView,
        meta: {
          parent: true,
          subHeaderName: ["Admin Dashboard", "Blogs"],
        },
      },
      {
        path: "settings/:full_name",
        name: "admin_setting",
        component: AdminSettingsView,
      },
      {
        path: "report-and-statictics",
        name: "admin_report_and_statistics",
        component: ReportAndStatisticsView,
        meta: {
          parent: false,
          subHeaderName: "Report And Statistics",
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {};
    if (to.meta.toTop) scroll.top = 0;
    if (to.meta.smoothScroll) scroll.behavior = "smooth";
    return scroll;
  },
});

// authentication for each route
router.beforeEach(async (to, _, next) => {
  if (to.meta.requiresAuth) {
    const { user, admin } = await checkAuthentication();
    if (user) {
      if (to.meta.requiresAdmin) {
        if (admin) {
          return next();
        }
        return next("/patient/dashboard");
      }
      return next();
    }
    return next("/auth/sign-in");
  }
  return next();
});

export default router;
