import axios from "@/api";


const state = {
  appointments: {
    all: [],
    pending: [],
    completed: [],
    expired: [],
    cancelled: [],
  },
  totalResults: 0,
  perPage: 10,
  totalPages: 1,
};

const getters = {
  getAppointmentsByStatus: (state) => (status) => {
    return state.appointments[status] || [];
  },
  totalPages: (state) => state.totalPages,
};

const mutations = {
  setAppointments(state, { status, appointments }) {
    state.appointments[status] = appointments;
  },
  setTotalResults(state, totalResults) {
    state.totalResults = totalResults;
  },
  setTotalPages(state, totalPages) {
    state.totalPages = totalPages;
  },
};

const actions = {
  async fetchPatientAppointments({ commit }, { status = "all", page = 1, perPage = 10 }) {
    const response = await axios.get(`/data/appointments`, {
      params: {
        status: status !== "all" ? status : undefined,
        page,
        per_page: perPage,
      },
    });

    const { appointments, total_results, per_page } = response.data;

    commit("setAppointments", { status, appointments });
    commit("setTotalResults", total_results);
    commit("setTotalPages", Math.ceil(total_results / per_page));

    return appointments;  // Return the appointments data

  },

  async bookAppointment({ dispatch }, payload) {
    const response = await axios.post(
      `/data/appointment/test_centre/${payload.id}`,
      payload.data
    );
    // After successful booking, fetch all appointments to update the UI
    await dispatch("fetchPatientAppointments", { status: "all", page: 1 });
    return response.data;
  },

  async bookLinkedAppointment({ dispatch }, payload) {
    const res = await axios.post(
      `/data/patient/connected/appointment/${payload.patient_id}/${payload.health_centre_id}`,
      payload.data
    );
    return res.data;
  },

  async getAvailableTimes({ commit }, { healthCentreId, date }) {

    const response = await axios.get(
      `/data/appointment/appointment_for_day/${healthCentreId}`, {
      params: { date }
    }
    );
    return response.data.available_slots;
  },


  async editAppointment({ dispatch }, payload) {

    const response = await axios.put(
      `data/appointment/${payload.appointmentId}`,
      payload.data
    );

    // After successful editing, fetch all appointments to update the UI
    await dispatch("fetchPatientAppointments", { status: "all", page: 1 });
    return response.data
  },

  async cancelAppointment({ dispatch }, payload) {
    const response = await axios.put(
      `data/appointment/delete/${payload.id}`,
      {
        reason_for_cancellation: payload.reason,
      }
    );
    // After successful cancellation, fetch all appointments to update the UI
    await dispatch("fetchPatientAppointments", { status: "all", page: 1 });
    return response.data.message;

  },

  async patientJoinsVoiceCall(_, payload) {
    const response = await axios.post(
      `/data/patient/join-call?room_id=${payload.room_id}`
    );

    return response.data
  },

  async patientJoinsVideoCall(_, payload) {
    const response = await axios.post(
      `/data/patient/join-video-call?room_id=${payload.room_id}`
    );

    return response.data
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
