<template>
  <section
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="bg-white w-full xl:mx-10 rounded-lg shadow-xl p-4 md:p-10">
      <h1 class="text-ResolutionBlue text-2xl font-bold mb-6">
        Diagnostic Imaging Hub
      </h1>

      <div
        class="w-full h-full border border-BlueChalk rounded-[18px] py-6 px-10"
      >
        <div class="flex justify-between items-center mb-6 flex-wrap gap-y-2">
          <h3 class="text-BalticSea text-base font-bold">Documents</h3>

          <div class="flex items-center gap-3 flex-wrap">
            <BaseButton
              v-if="!isAdmin"
              class="bg-ResolutionBlue text-white font-semibold rounded-[40px] py-1.5 px-3 flex justify-between items-center gap-1"
              :class="isLoading && 'opacity-50'"
              :disabled="isLoading"
              @click="openReportAndStatisticsModal"
            >
              <img :src="circleWhiteIcon" alt="add circle icon" />
              <span class="text-xs">Upload</span>
            </BaseButton>
          </div>
        </div>

        <!-- Loading Indicator -->
        <div
          v-if="isPending"
          class="w-full mx-auto h-64 flex justify-center items-center"
        >
          <div class="w-5 h-5">
            <SpinnerIcon />
          </div>
        </div>

        <!-- No Data Message -->
        <NoDataMessage
          v-else-if="isSuccess && documentsData?.total_items === 0"
          text="You have no health reports in your Document Hub."
        />

        <ErrorMessage v-else-if="isError" />

        <!-- Table with Documents -->
        <div v-else-if="isSuccess" class="custom-scrollbar overflow-auto">
          <Table :columns="columns" :tableData="paginatedDocument" />
        </div>

        <Teleport to="body">
          <ReportActionBox
            v-if="actionBoxVisible"
            :pdf="
              paginatedDocument.find((doc) => doc.id === actionBoxVisible)
                ?.documents
            "
            :style="{
              top: `${actionBoxPosition.top}px`,
              left: `${actionBoxPosition.left}px`,
              position: 'absolute',
              zIndex: 1000,
            }"
            @delete="handleDelete(actionBoxVisible)"
            @close="toggleActionBox"
          />
        </Teleport>

        <!-- Pagination -->
        <Pagination
          :current-page="currentPage"
          :number-of-pages="totalPages"
          :is-pending="isPending"
          :fetching-next-page="isLoading"
          @prevPage="currentPage--"
          @nextPage="currentPage++"
          @goToPage="(page) => (currentPage = page)"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { inject, ref, computed, watch, h } from "vue";
import { useQuery, useMutation } from "@tanstack/vue-query";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useStore } from "vuex";
import { push } from "notivue";
import { dateFormatter } from "@/utils/dateFormatter";
import debounce from "lodash/debounce";
import ReportActionBox from "@/components/main/patient/reportAndStatistics/ReportActionBox.vue";
import optionButton from "@/assets/icons/option-button.svg";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import circleWhiteIcon from "@/assets/icons/add_circle_white.svg";
import Pagination from "@/components/main/ui/Pagination.vue";

const {
  "user/getAllDocumentUploads": getAllDocumentUploads,
  "user/deleteDocument": deleteDocument,
} = mapActions();

const openReportAndStatisticsModal = inject("openReportAndStatisticsModal");
const openReportNoteModal = inject("openReportNoteModal");
const reportNote = inject("reportNote");

const isAdmin = computed(() => useStore().getters["auth/getIsAdmin"]);

const setCurrentNote = (val) => {
  reportNote.value = val;
  openReportNoteModal();
};

const closeActionBox = () => {
  currentIndex.value = null;
};

const currentPage = ref(1);
const itemsPerPage = 10;
const actionBoxVisible = ref(null);
const actionBoxPosition = ref({ top: 0, left: 0 });

// Toggle the visibility of the action box
const toggleActionBox = (event = null, recordId = null) => {
  if (event) {
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    actionBoxPosition.value = {
      top: top + height + window.scrollY,
      left: left + window.scrollX,
    };
  }
  actionBoxVisible.value =
    actionBoxVisible.value === recordId ? null : recordId;
};

// Handle delete action
const handleDelete = (documentId) => {
  deleteReport(documentId);
};

const columns = ref([
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (record) => h("span", null, dateFormatter(record.created_at)),
  },
  {
    title: "Type",
    dataIndex: "procedure",
    key: "procedure",
  },
  {
    title: "Notes",
    dataIndex: "body_part",
    key: "body_part",
  },
  {
    title: "Actions",
    key: "actions",
    render: (record) =>
      h("div", { class: "relative" }, [
        h("img", {
          src: optionButton,
          class: "cursor-pointer",
          onClick: (event) => toggleActionBox(event, record.id),
        }),
      ]),
  },
]);

// Fetch documents using vue-query and Vuex action
const {
  data: documentsData,
  isPending,
  isSuccess,
  isError,
  refetch,
} = useQuery({
  queryKey: ["allDocuments", currentPage],
  queryFn: () => getAllDocumentUploads({ page: currentPage.value }),
  keepPreviousData: true,
  onSuccess: (data) => {},
  onError: (error) => {},
});

// Delete document using useMutation
const { mutate: deleteReport, isLoading } = useMutation({
  mutationFn: (documentId) => deleteDocument(documentId),
  onSuccess: () => {
    push.success("Document deleted successfully.");
    refetch(); // Refetch data after deletion
  },
  onError: () => {
    push.error("Error deleting document.");
  },
});

// Pagination controls
const visiblePages = computed(() => {
  const maxVisible = 5;
  let startPage = Math.max(1, currentPage.value - Math.floor(maxVisible / 2));
  let endPage = Math.min(totalPages.value, startPage + maxVisible - 1);
  if (endPage - startPage + 1 < maxVisible && totalPages.value >= maxVisible) {
    startPage = Math.max(1, endPage - maxVisible + 1);
  }
  return Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
});

const debouncedFetchDocuments = debounce(() => refetch(), 500);
watch(currentPage, debouncedFetchDocuments);

// Computed property for paginated document data
const paginatedDocument = computed(() => {
  const data = documentsData?.value?.document_uploads || [];
  return data;
});

// Computed property for total pages
const totalPages = computed(() => {
  const totalItems = documentsData.value?.total_items || 0;
  const pages = Math.ceil(totalItems / itemsPerPage);
  return pages;
});

// Watch for changes in currentPage and log it
watch(currentPage, (newPage) => {
  refetch();
});
</script>
