<template>
  <div
    class="w-full flex items-center justify-center text-sm font-bold text-TurbhamGreen mt-4"
  >
    <span class="border-r-2 border-r-ResolutionBlue pr-5">TO: >1:80 Titre</span>
    <span class="border-r-2 border-r-ResolutionBlue px-5"
      >TH: >1:160 Titre</span
    >
    <span class="border-r-2 border-r-ResolutionBlue px-5"
      >AH: >1:320 Titre</span
    >
    <span class="pl-5">BH: >1:320 Titre</span>
  </div>
</template>

<script setup></script>

<style scoped></style>
