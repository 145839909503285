<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 space-y-6"
    >
      <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <LinkedAccountProfileDetails
          :patientDetails="patient_details"
          :linkedAccount="linkedPatient"
        />
        <AssistLinkedAccount :patientDetails="patient_details" />
      </div>
      <div class="border rounded-[20px] pt-11 px-10 pb-7">
        <div class="flex items-center justify-between gap-2 mb-6">
          <p class="font-bold">Linked Account Vitals</p>

          <div class="flex justify-between items-center gap-5">
            <div
              class="bg-BlueChalk rounded-[18px] py-1 px-1.5 flex items-center gap-[9px]"
            >
              <button
                @click="showChart = true"
                class="py-[5px] px-[9px]"
                :class="showChart && 'bg-ResolutionBlue rounded-[18px]'"
              >
                <img
                  :src="showChart ? chartIconLight : chartIcon"
                  alt="chart icon"
                />
              </button>

              <button
                @click="showChart = false"
                class="py-[5px] px-[9px]"
                :class="!showChart && ' bg-ResolutionBlue rounded-[18px]'"
              >
                <img
                  :src="!showChart ? listIcon : listIconDark"
                  alt="list icon"
                />
              </button>
            </div>

            <button class="bg-ResolutionBlue/[0.05] p-2 rounded-md">
              <img :src="fileShareIcon" alt="share icon" />
            </button>
          </div>
        </div>

        <!-- Reusable FilterHeader Component -->
        <div class="sticky top-0 z-10 bg-white mb-5">
          <FilterHeader
            title="Linked Account Vitals"
            :selectedFilter="selectedFilter"
            :selectedTestType="selectedTestType"
            :showFilterDropdown="showFilterDropdown"
            :showTestTypeDropdown="showTestTypeDropdown"
            :average_high="average_high"
            :isLinkedAccount="true"
            @toggleFilterDropdown="toggleFilterDropdown"
            @toggleTestTypeDropdown="toggleTestTypeDropdown"
            @setFilter="setFilter"
            @setTestType="setTestType"
          />
        </div>

        <div v-if="!showChart" class="h-[90%]">
          <!-- Table with fetched records -->
          <div class="flex-1 h-full flex flex-col py-6">
            <div
              v-if="isPending && !isPlaceholderData"
              class="self-center flex justify-center items-center w-6 h-6"
            >
              <SpinnerIcon />
            </div>

            <!-- Show message if no data is available -->
            <NoDataMessage
              v-else-if="isSuccess && details?.length === 0"
              text="You have no test vitals"
            />
            <!-- Show error if fetch fails -->
            <ErrorMessage v-else-if="isError && !isPending" />
            <!-- Show table if data is successfully fetched or cached data is available -->

            <div
              v-else-if="isSuccess || details.length > 0"
              class="custom-scrollbar overflow-auto"
            >
              <Table :columns="columns" :tableData="details" />
            </div>
          </div>
        </div>

        <GlucoseLevelChart v-else />

        <!-- Pagination -->
        <div v-if="!showChart">
          <Pagination
            :current-page="currentPage"
            :number-of-pages="totalPages"
            :is-pending="isPending"
            :fetching-next-page="false"
            @prevPage="handlePrevPage"
            @nextPage="handleNextPage"
            @goToPage="handleGoToPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  watchEffect,
  h,
  watch,
  computed,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/vue-query";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { push } from "notivue";
import { dateFormatter } from "@/utils/dateFormatter";
import { timeFormatter } from "@/utils/dateFormatter";
import { testTypes, typeMapping } from "@/utils/mockData/testTypes";
import FilterHeader from "@/components/main/patient/testRecords/FilterHeader.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";
import GlucoseLevelChart from "@/components/main/patient/accountLinking/GlucoseLevelChart.vue";
import chartIcon from "@/assets/icons/fi_bar-chart-2.svg";
import chartIconLight from "@/assets/icons/fi_bar-chart-2-light.svg";
import listIcon from "@/assets/icons/fi_list.svg";
import listIconDark from "@/assets/icons/fi_list-dark.svg";
import LinkedAccountProfileDetails from "@/components/main/patient/accountLinking/LinkedAccountProfileDetails.vue";
import AssistLinkedAccount from "@/components/main/patient/accountLinking/AssistLinkedAccount.vue";
import arrowDropDownIcon from "@/assets/icons/arrow_drop_down.svg";
import fileShareIcon from "@/assets/icons/fi_share.svg";
import Pagination from "@/components/main/ui/Pagination.vue";
import TestActions from "@/components/main/patient/testCenter/dropdowns/TestActions.vue";
import optionButton from "@/assets/icons/option-button.svg";

const route = useRoute();
const store = useStore();
const queryClient = useQueryClient();
const id = route.params.id;
const details = ref([]);
const showChart = ref(false);
const patient_details = ref({});
const linkedPatient = ref({});
const average_high = ref(80);
const selectedTestType = ref(testTypes[0]);
const showTestTypeDropdown = ref(false);
const selectedFilter = ref("All");
const showFilterDropdown = ref(false);
const columns = ref([]);
const totalResults = ref(0);
const currentPage = ref(1);
const perPage = ref(10);
const menuStates = ref({});
const activeMenuId = ref(null);
const menuContainers = ref({});

const {
  "accountConnection/getLinkedAccountVitals": getLinkedAccountVitals,
  "test/deleteATestForLinkedPatient": deleteATestForLinkedPatient,
} = mapActions();

const openLinkedAccountManualTestModal = inject(
  "openLinkedAccountManualTestModal"
);

const userProfile = computed(() => store.getters["user/getPatientProfile"]);
const userId = computed(() => userProfile.value?.id);

// Event handlers for FilterHeader
const toggleFilterDropdown = () =>
  (showFilterDropdown.value = !showFilterDropdown.value);

const toggleTestTypeDropdown = () =>
  (showTestTypeDropdown.value = !showTestTypeDropdown.value);

const setFilter = (filter) => {
  selectedFilter.value = filter;
  showFilterDropdown.value = false;
};
const setTestType = (testType) => {
  selectedTestType.value = testType;
  showTestTypeDropdown.value = false;
  updateColumns(); // Update columns based on selected test type
};

const { data, isPending, isPlaceholderData, isSuccess, isError, refetch } =
  useQuery({
    queryKey: [
      "linked-account-vitals",
      id,
      selectedTestType,
      selectedFilter,
      currentPage,
    ],
    queryFn: () =>
      getLinkedAccountVitals({
        id,
        test_type: selectedTestType.value.type,
        time_period:
          selectedFilter.value === "All"
            ? null
            : selectedFilter.value.toLowerCase().replace(" ", "_"),
        page: currentPage.value,
      }),
    placeholderData: keepPreviousData,
  });

// Pagination logic
const totalPages = computed(() =>
  Math.ceil(totalResults.value / perPage.value)
);

const handlePrevPage = () => {
  if (currentPage.value > 1) currentPage.value--;
};

const handleNextPage = () => {
  if (currentPage.value < totalPages.value) currentPage.value++;
};

const handleGoToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) currentPage.value = page;
};

// Define columns based on test type
const updateColumns = () => {
  const testType = selectedTestType.value.type;
  // Define the "Date" column to come first
  const dateColumn = {
    title: "Date",
    dataIndex: "check_time",
    render: (record) => h("span", null, dateFormatter(record.check_time)),
  };

  const testTypeColumn = {
    title: "Test Type",
    dataIndex: "type",
    render: (record) => {
      const testType = typeMapping[record.type] || "Unknown";
      return h("span", { class: "font-medium text-gray-600" }, testType);
    },
  };
  // Define specific columns based on test type
  const testTypeColumns = {
    glucose: [
      {
        title: "Glucose Level",
        dataIndex: "glucose_level",
        render: (record) => {
          const glucoseClass =
            record.glucose_level < 70
              ? "text-DodgerBlue font-bold"
              : record.glucose_level <= 100
              ? "text-DarkMint font-bold"
              : record.glucose_level <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: glucoseClass },
            `${record.glucose_level} ${record.units}`
          );
        },
      },
      {
        title: "Meal",
        dataIndex: "before_meal",
        render: (record) =>
          h("span", null, record.before_meal ? "Before meal" : "After meal"),
      },
    ],
    heart_rate: [
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
      {
        title: "Oxygen Saturation",
        dataIndex: "oxygen_saturation",
        render: (record) =>
          h(
            "span",
            null,
            `${record.oxygen_saturation} ${record.oxygen_saturation_unit}`
          ),
      },
      {
        title: "Perfusion Index",
        dataIndex: "perfusion_index",
        render: (record) =>
          h(
            "span",
            null,
            `${record.perfusion_index} ${record.perfusion_index_unit}`
          ),
      },
    ],
    blood_pressure: [
      {
        title: "Systolic",
        dataIndex: "systolic",
        render: (record) =>
          h("span", null, `${record.systolic} ${record.units}`),
      },
      {
        title: "Diastolic",
        dataIndex: "diastolic",
        render: (record) =>
          h("span", null, `${record.diastolic} ${record.units}`),
      },
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
    ],
    weight: [
      {
        title: "Weight",
        dataIndex: "weight",
        render: (record) => h("span", null, `${record.weight} ${record.units}`),
      },
    ],
    temperature: [
      {
        title: "Temperature",
        dataIndex: "temperature",
        render: (record) => {
          const temperatureClass =
            record.temperature < 70
              ? "text-DodgerBlue font-bold"
              : record.temperature <= 100
              ? "text-DarkMint font-bold"
              : record.temperature <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: temperatureClass },
            `${record.temperature} ${record.units}`
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => h("span", null, `${record.status}`),
      },
      {
        title: "Description",
        dataIndex: "description",
        render: (record) => h("span", null, `${record.description}`),
      },
    ],
    ecg: [
      {
        title: "Heart Rate",
        dataIndex: "heart_rate",
        render: (record) => {
          const heartClass =
            record.heart_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.heart_rate <= 100
              ? "text-DarkMint font-bold"
              : record.heart_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: heartClass },
            `${record.heart_rate} ${record.units}`
          );
        },
      },
      {
        title: "Analysis",
        dataIndex: "analysis",
        render: (record) => h("span", null, `${record.analysis}`),
      },
    ],
    lung_capacity: [
      {
        title: "FEV1",
        dataIndex: "fev1",
        render: (record) =>
          h("span", null, `${record.fev1} ${record.fev1_unit}`),
      },
      {
        title: "PEF",
        dataIndex: "pef",
        render: (record) => h("span", null, `${record.pef} ${record.pef_unit}`),
      },
    ],
    malaria: [
      {
        title: "Note",
        dataIndex: "note",
        render: (record) => {
          const maxLength = 20; // maximum length for truncation
          const note = record.malaria_parasite_note || "";
          const truncatedNote =
            note.length > maxLength ? `${note.slice(0, maxLength)}...` : note;

          return h(
            "span",
            {
              class: "cursor-pointer",
              title: note, // Show the full text on hover
            },
            truncatedNote
          );
        },
      },
    ],
    typhoid: [
      {
        title: "Typhi O",
        dataIndex: "typhi_o",
        render: (record) => h("span", null, `${record["Typhi O (TO)"]}`),
      },
      {
        title: "Typhi H",
        dataIndex: "typhi_h",
        render: (record) => h("span", null, `${record["Typhi H (TH)"]}`),
      },
      {
        title: "Paratyphi A,H",
        dataIndex: "paratyphi_a_h",
        render: (record) => h("span", null, `${record["Paratyphi A,H (AH)"]}`),
      },
      {
        title: "Paratyphi B,H",
        dataIndex: "paratyphi_b_h",
        render: (record) => h("span", null, `${record["Paratyphi B,H (BH)"]}`),
      },
    ],
    hepatitis: [
      {
        title: "Result",
        dataIndex: "result",
        render: (record) => h("span", null, `${record.hepatitis_b}`),
      },
    ],
    hiv: [
      {
        title: "Result",
        dataIndex: "result",
        render: (record) => h("span", null, `${record.hiv}`),
      },
    ],
  };
  // Common columns for all test types
  const commonColumns = [
    {
      title: "Time",
      dataIndex: "check_time",
      render: (record) => h("span", null, timeFormatter(record.check_time)),
    },
    {
      title: "Input",
      dataIndex: "input",
    },
    {
      title: "Uploaded By",
      dataIndex: "uploaded_by",
      render: (record) => {
        const uploadedBy = record.attendant?.full_name || "__"; // Render "__" if attendant is null
        return h("span", null, uploadedBy);
      },
    },
    {
      title: "Date Updated",
      dataIndex: "updated_at",
      render: (record) => {
        return h("span", null, dateFormatter(record.updated_at));
      },
    },
    {
      title: "",
      render: (record) => {
        const connectionId = record.connection_id;

        if (record.input === "Manual Input" && connectionId !== null) {
          return h(
            "div",
            {
              ref: (el) => (menuContainers.value[record.id] = el),
              class: "relative",
            },
            [
              h(
                "button",
                {
                  class:
                    "hover:bg-WhiteLilac rotate-90 p-2 text-2xl md:text-3xl font-bold rounded flex justify-center items-center transition-colors duration-300",

                  onClick: (e) => showActionsMenu(e, record.id),
                },
                h("img", {
                  src: optionButton,
                  alt: "three dots",
                })
              ),
              h(TestActions, {
                position: dropdownPosition.value,
                visible: menuStates.value[record.id],
                isDeleting: deleteManualTestMutation.isPending.value,
                onEdit: () => handleEditClick(record),
                onDelete: () => handleDeleteClick(record),
                onClose: () => (menuStates.value[record.id] = false),
              }),
            ]
          );
        }
      },
    },
  ];
  // Combine columns with the "Date" column as the first column
  columns.value = [
    dateColumn,
    testTypeColumn,
    ...(testTypeColumns[testType] || []),
    ...commonColumns,
  ];
};

// Click outside handler
const handleClickOutside = (event) => {
  const menuContainer = menuContainers.value[activeMenuId.value];
  if (menuContainer && !menuContainer.contains(event.target)) {
    menuStates.value[activeMenuId.value] = false;
    activeMenuId.value = null;
    document.removeEventListener("click", handleClickOutside);
  }
};

const dropdownPosition = ref({ x: 0, y: 0 });

// Function to toggle the menu visibility
const toggleActionMenu = (id) => {
  Object.keys(menuStates.value).forEach((key) => {
    if (key !== id.toString()) menuStates.value[key] = false;
  });

  const wasOpen = menuStates.value[id];
  menuStates.value[id] = !wasOpen;

  if (!wasOpen) {
    activeMenuId.value = id;
    document.addEventListener("click", handleClickOutside);
  } else {
    activeMenuId.value = null;
    document.removeEventListener("click", handleClickOutside);
  }
};

const showActionsMenu = (event, recordId) => {
  const button = event.target.closest("button");
  if (!button) return;

  const rect = button.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  dropdownPosition.value = {
    x: rect.left + scrollLeft,
    y: rect.top + rect.height + scrollTop,
  };

  toggleActionMenu(recordId);
};

// Cleanup event listener
onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

// Initialize columns on load and update when test type changes
watch(selectedTestType, updateColumns, { immediate: true });

watchEffect(() => {
  updateColumns();
  if (data.value) {
    details.value = data.value.patient_results;
    linkedPatient.value = data.value;
    patient_details.value = data.value.patient_details;
    totalResults.value = data.value.total || 0;
    perPage.value = data.value.per_page || 10;
    currentPage.value = data.value.current_page || 1;
  }
});

// Mutation for deleting manual test
const deleteManualTestMutation = useMutation({
  mutationFn: async ({ testType, testTypeId, patientId }) => {
    const response = await deleteATestForLinkedPatient({
      testType,
      testTypeId,
      patientId,
    });

    if (!response.success) {
      throw new Error(response.message);
    }
    return response;
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ["linked-account-vitals"] });
    push.success("Test deleted successfully!");
  },
  onError: (error) => {
    push.error(error.message || "Error deleting the test.");
  },
});

// Add the function to handle edit clicks
const handleEditClick = (item) => {
  if (!item || !patient_details.value) {
    return;
  }

  const patientName = patient_details.value.full_name; // Get patient name from details
  const patientId = patient_details.value.id; // Get patient id from details
  const glucoseLevelId = item.id;

  // Prepare the data to pass to the modal
  const existingData = {
    glucose_level: item.glucose_level,
    before_meal: item.before_meal,
    check_time: item.check_time,
    input: item.input,
    units: item.units,
    created_at: item.created_at,
    glucose_level_id: glucoseLevelId,
  };

  // Open the modal with the test data
  openLinkedAccountManualTestModal(patientId, patientName, existingData);

  menuStates.value[record.id] = false;
  activeMenuId.value = null;
};

const handleDeleteClick = async (record) => {
  try {
    const patientId = patient_details.value.id;

    await deleteManualTestMutation.mutateAsync({
      testType: typeMapping[record.type],
      testTypeId: record.id,
      patientId: patientId,
    });

    setTimeout(() => {
      menuStates.value[record.id] = false;
      activeMenuId.value = null;
    }, 200);
  } catch (error) {
    push.error(error.message || "Error deleting the test.");
  }
};
</script>
