<template>
  <ModalWrapper>
    <div
      class="bg-white w-full max-w-[1080px] flex flex-col gap-3.5 p-6 rounded-3xl md:p-[50px] md:gap-9 lg:rounded-[38px] relative"
    >
      <div class="w-full flex justify-between items-center">
        <h3 class="text-GunMetal text-base md:text-xl font-bold">
          Test Center
        </h3>
        <div class="w-6 h-6 cursor-pointer" @click="closeTakeTestModal">
          <img :src="closeIcon" alt="close" class="w-full h-full" />
        </div>
      </div>

      <form class="w-full flex flex-col gap-6 md:flex-row">
        <div class="w-full space-y-6">
          <!-- Name Input with searchPatient component -->
          <div>
            <label class="block text-xs font-normal text-SkyGrey">Name</label>
            <input
              type="text"
              v-model="fullName"
              class="block w-full py-2 border-b border-BlueChalk text-sm font-bold text-DavyGrey bg-transparent"
              disabled
            />
          </div>

          <!-- Test Type Dropdown -->
          <div>
            <label class="block text-xs font-normal text-SkyGrey"
              >Test Type</label
            >
            <div
              class="relative w-full flex items-center justify-between mt-1 border-b border-BlueChalk bg-transparent cursor-pointer"
              @click.stop="toggleTestTypeDropdown"
            >
              <p
                class="block w-full py-2 text-sm font-bold text-DavyGrey bg-transparent"
              >
                {{ selectedTestType }}
              </p>

              <div class="w-4 h-4 cursor-pointer">
                <img
                  :src="DropdownArrow"
                  alt="dropdown arrow"
                  class="w-full h-full"
                />
              </div>
              <!-- Use v-show instead of v-if for smoother transitions -->
              <Teleport to="body">
                <div
                  v-show="isTestTypeDropdownOpen"
                  class="fixed inset-0 z-40"
                  @click="closeDropdown"
                ></div>
              </Teleport>

              <Transition name="dropdown-wrapper">
                <div
                  v-show="isTestTypeDropdownOpen"
                  class="absolute w-full z-50"
                >
                  <DropdownWrapper>
                    <TestTypesDropdown
                      @selectTestType="onTestTypeSelect"
                      @click.stop
                    />
                  </DropdownWrapper>
                </div>
              </Transition>
            </div>
          </div>
        </div>

        <!-- Device Selection Section -->
        <div class="w-full relative">
          <!-- If a device is selected, show the selected device view -->
          <div class="space-y-1 col-span-1" v-if="selectedDeviceView">
            <p class="text-sm font-bold text-OlsoGrey md:text-base">
              Selected Device
            </p>

            <div class="p-3.5 bg-WhiteLilac rounded-lg">
              <div
                class="flex justify-between items-center px-4 py-2.5 bg-white rounded-lg"
              >
                <div class="flex items-center gap-[18px]">
                  <div
                    class="w-14 h-[50px] border-[0.5px] border-SkyGrey rounded-sm"
                  >
                    <img
                      :src="selectedDeviceImage"
                      alt="Selected Device"
                      class="w-full h-full"
                    />
                  </div>
                  <div>
                    <p class="text-xs text-SkyGrey font-normal">
                      {{ selectedTestType }}
                    </p>
                    <p class="font-bold text-sm text-OlsoGrey">
                      {{ selectedDeviceView }}
                    </p>
                  </div>
                </div>
                <div
                  class="w-[18px] h-[18px] cursor-pointer"
                  @click="clearSelectedDevice"
                >
                  <img
                    :src="closeIcon"
                    alt="Clear Selection"
                    class="w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- If no device is selected, show the device selection interface -->
          <transition name="fade">
            <div v-if="!selectedDeviceView">
              <div
                class="w-full flex items-center justify-between px-2.5 py-4 border border-BlueChalk text-sm font-bold text-DavyGrey bg-transparent rounded cursor-pointer"
                @click="toggleDeviceSelection"
              >
                <p>Take a new test</p>
                <div class="w-4 h-4">
                  <img
                    :src="DropdownArrow"
                    alt="dropdown arrow"
                    class="w-full h-full"
                  />
                </div>
              </div>

              <transition name="fade">
                <div
                  v-if="showDeviceSelection"
                  class="absolute top-16 left-0 right-0 w-full flex flex-col gap-6 p-[18px] bg-white border border-BlueChalk text-sm text-DarkJungleGreen font-normal rounded-lg z-10"
                >
                  <section class="w-full space-y-2">
                    <div
                      class="w-full flex items-center justify-between cursor-pointer"
                      @click="toggleDeviceOptions"
                    >
                      <p>Select a Device</p>
                      <div class="w-2.5 h-2.5">
                        <img
                          :src="DropdownArrow"
                          alt="dropdown arrow"
                          class="w-full h-full"
                        />
                      </div>
                    </div>

                    <transition name="fade">
                      <div
                        v-if="showDeviceOptions && isSuccess"
                        class="ml-2.5 md:ml-5 flex flex-col gap-2.5"
                      >
                        <!-- If there are devices, show them -->
                        <div
                          v-for="device in data.medical_devices"
                          :key="device.id"
                          class="flex items-center gap-4 cursor-pointer"
                          @click="
                            selectDevice(
                              device.type,
                              getDeviceImage(device.type),
                              device.id
                            )
                          "
                        >
                          <div class="w-[30px] h-[30px]">
                            <img
                              :src="getDeviceImage(device.type)"
                              alt="Device"
                              class="w-full h-full"
                            />
                          </div>
                          <div class="w-full flex items-center justify-between">
                            <p>{{ device.type }}</p>
                            <p>{{ device.sn }}</p>
                          </div>
                        </div>

                        <!-- If no devices, show fallback message -->
                        <div
                          v-if="!data?.medical_devices.length"
                          class="text-center"
                        >
                          No devices found.
                        </div>
                      </div>

                      <div
                        v-else-if="
                          isSuccess && data.medical_devices.length === 0
                        "
                      >
                        No device linked yet -

                        <a
                          href="https://paystack.shop/turbomedics-store"
                          target="_blank"
                          class="bg-ResolutionBlue rounded-full px-2 py-1 text-white font-medium text-xs hover:bg-DodgerBlue hover:text-white transition-all duration-300"
                          >Shop Now</a
                        >
                      </div>
                    </transition>
                  </section>
                  <div
                    class="w-full text-left cursor-pointer"
                    @click="openManualTestModalWithType"
                  >
                    Manually Input Test
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </form>

      <!-- Cancel Button & Run Test Button -->
      <div class="flex items-center gap-2.5 self-center">
        <BaseButton
          @click="closeTakeTestModal"
          class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
        >
          Cancel
        </BaseButton>

        <BaseButton
          @click="handleRunTestClick"
          class="px-[52px] py-3 bg-ResolutionBlue text-white rounded-full text-sm font-semibold"
          :disabled="isLoading"
        >
          <span v-if="isLoading">
            <LoadingSpinner />
          </span>
          <span v-else> Run Test </span>
        </BaseButton>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref, inject, computed, watch, onMounted, onUnmounted } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { push } from "notivue";
import { useStore } from "vuex";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import ModalWrapper from "./ModalWrapper.vue";
import TestTypesDropdown from "../../testRecords/dropdowns/TestTypesDropdown.vue";
import DropdownWrapper from "../../dropdowns/DropdownWrapper.vue";
import DropdownArrow from "@/assets/icons/down_caret.svg";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import GlucoseMonitor from "@/assets/icons/4g-glucometer.svg";
import SmartGlucometer from "@/assets/icons/smart-glucometer.svg";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import closeIcon from "@/assets/icons/close.svg";

const store = useStore();

const closeTakeTestModal = inject("closeTakeTestModal");
const openTestInstructionModal = inject("openTestInstructionModal");
const openManualTestModal = inject("openManualTestModal");
const updateSelectedDevice = inject("updateSelectedDevice");

const isLoading = ref(false);
const selectedTestType = ref("Glucose");
const isTestTypeDropdownOpen = ref(false);
const selectedDeviceView = ref(null);
const selectedDeviceImage = ref(null);
const selectedDeviceId = ref(null);
const showDeviceSelection = ref(false);
const showDeviceOptions = ref(false);
const userFullName = ref("");

const {
  "test/getMedicalDevices": getMedicalDevices,
  "user/fetchPatientProfile": fetchPatientProfile,
} = mapActions();

const { "user/getPatientProfile": getPatientProfile } = mapGetters();

const fullName = getPatientProfile?.value?.person?.full_name;

const { data, isSuccess } = useQuery({
  queryKey: ["devices"],
  queryFn: getMedicalDevices,
});

// Fetch user profile on component mount using useQuery
const { data: patientProfileData, isSuccess: isProfileFetched } = useQuery({
  queryKey: ["patient-profile"],
  queryFn: fetchPatientProfile,
});

// Device Image Getter Function
const getDeviceImage = (deviceType) => {
  if (deviceType.includes("Glucometer")) {
    return GlucoseMonitor;
  } else if (deviceType.includes("Smart")) {
    return SmartGlucometer;
  }
  return GlucoseMonitor; // Fallback image
};

const closeDropdown = () => {
  isTestTypeDropdownOpen.value = false;
};

const toggleTestTypeDropdown = (e) => {
  e.stopPropagation();
  isTestTypeDropdownOpen.value = !isTestTypeDropdownOpen.value;
};

const onTestTypeSelect = (type) => {
  selectedTestType.value = type.name;
  closeDropdown();
};

const toggleDeviceSelection = () => {
  showDeviceSelection.value = !showDeviceSelection.value;
  showDeviceOptions.value = false;
};

const toggleDeviceOptions = () => {
  showDeviceOptions.value = !showDeviceOptions.value;
};

// Function to select a device and store its ID
const selectDevice = (deviceName, deviceImage, deviceId) => {
  selectedDeviceView.value = deviceName;
  selectedDeviceImage.value = deviceImage;
  selectedDeviceId.value = deviceId;
  showDeviceSelection.value = false;
  updateSelectedDevice(deviceName, deviceImage, selectedTestType.value);
};

const clearSelectedDevice = () => {
  selectedDeviceView.value = null;
  selectedDeviceImage.value = null;
};

// Open the ManualTestModal with patientId (selected or default)
const openManualTestModalWithType = () => {
  const currentPatientId = store.getters["user/getPatientId"];
  openManualTestModal(selectedTestType.value, currentPatientId);
};

// Handle Run Test button click
const handleRunTestClick = async () => {
  if (selectedDeviceView.value) {
    isLoading.value = true;
    try {
      // Open the TestInstructionModal with the necessary data
      openTestInstructionModal({
        selectedDeviceImage: selectedDeviceImage.value,
        selectedTestType: selectedTestType.value,
      });
    } catch (error) {
      push.error("Failed to fetch the medical device.");
    } finally {
      isLoading.value = false;
    }
  } else {
    push.error("Please select a device before running the test.");
  }
};

onMounted(() => {
  document.addEventListener("click", (event) => {
    const dropdown = document.querySelector(".test-type-dropdown");
    if (!dropdown?.contains(event.target)) {
      isTestTypeDropdownOpen.value = false;
    }
  });
});

onUnmounted(() => {
  document.removeEventListener("click", (event) => {
    const dropdown = document.querySelector(".test-type-dropdown");
    if (!dropdown?.contains(event.target)) {
      isTestTypeDropdownOpen.value = false;
    }
  });
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
