<template>
  <div
    class="bg-GhostWhite border-[0.1px] border-GreenWhite px-6 py-4 rounded-xl"
  >
    <h5 class="font-semibold text-lg mb-2">Email OTP</h5>
    <p class="text-MistBlue text-xs md:text-sm md:max-w-[22rem] mb-4">
      Use the button bellow to generate time-sensitive authentication codes that
      will be sent to your email
    </p>
    <div>
      <div
        v-if="getPatientProfile?.person?.email_otp_enabled"
        class="flex items-center space-x-3 w-[80%] my-2"
      >
        <span
          class="custom-button font-medium bg-Geyser text-sm md:text-[14px] rounded-3xl text-MistBlue py-2 px-10 duration-500"
        >
          Enabled
        </span>
        <button
          @click="requestEmailOtpToDisable"
          class="border border-MistBlue custom-button font-medium text-sm md:text-[14px] rounded-3xl text-Gravel py-2 px-10 duration-500"
        >
          <span v-if="isLoading" class="pl-3">
            <LoadingSpinner />
          </span>
          <span v-else>Remove 2FA</span>
        </button>
      </div>
      <div v-else>
        <button
          @click="requestEmailOtp"
          class="custom-button font-medium bg-ResolutionBlue text-sm md:text-[14px] rounded-3xl text-white py-2 px-10 duration-500 w-52"
        >
          <span v-if="isLoading" class="pl-3">
            <LoadingSpinner />
          </span>
          <span v-else>Use email OTP</span>
        </button>
      </div>
    </div>
  </div>
  <EmailOtpModal v-if="showModal" />
  <DisableTwoFaViaEmailOtp v-if="showDisableModal" />
</template>

<script setup>
import { ref, provide, onMounted } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import EmailOtpModal from "./EmailOtpModal.vue";
import DisableTwoFaViaEmailOtp from "./DisableTwoFAViaEmailOtp.vue";
import { push } from "notivue";
import BaseButton from "@/components/main/ui/BaseButton.vue"

const {
  "twoFactorAuthentication/loggedOutPatientRequestForEmailOtp":
    loggedOutPatientRequestForEmailOtp,
  "twoFactorAuthentication/requestForEmailOtp": requestForEmailOtp,
  "user/fetchPatientProfile": fetchPatientProfile,
} = mapActions();

const { "user/getPatientProfile": getPatientProfile } = mapGetters();

const showModal = ref(false);
const showDisableModal = ref(false);
const isLoading = ref(false);

const requestEmailOtp = async () => {
  try {
    isLoading.value = true;
    const requestOtp = await requestForEmailOtp();
    if (requestOtp) {
      showModal.value = true;
    }
  } catch (error) {
    push.error("Error occured, pls try again");
    console.error("requestForEmailOtp in com errror", error);
  } finally {
    isLoading.value = false;
  }
};

const requestEmailOtpToDisable = async () => {
  try {
    isLoading.value = true;
    const requestOtp = await loggedOutPatientRequestForEmailOtp(
      getPatientProfile?.value.person.email
    );
    if (requestOtp) {
      showDisableModal.value = true;
    }
  } catch (error) {
    push.error("Error occured, pls try again");
    console.error("requestForEmailOtp in com errror", error);
  } finally {
    isLoading.value = false;
  }
};

const closeDisableModal = () => {
  showDisableModal.value = false;
};

const closeModal = () => {
  showModal.value = false;
};

provide("closeModal", closeModal);
provide("closeDisableModal", closeDisableModal);

onMounted(async () => {
  await fetchPatientProfile();
});
</script>

<script>
export default {
  name: "AuthenticationViaEmail",
};
</script>
