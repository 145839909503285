<template>
  <div class="space-y-6 w-full mx-auto">
    <div
      v-if="isPending"
      class="flex items-center justify-center mx-auto mt-20 self-center w-6 h-6"
    >
      <SpinnerIcon />
    </div>
    <NotificationSettingDisplay
      v-for="(section, sectionIndex) in notificationSettings"
      :key="sectionIndex"
      :section="section"
      :disabled="determineDisabledState(section)"
      @toggle="(event) => updateNotification(sectionIndex, event)"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { push } from "notivue";
import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import NotificationSettingDisplay from "@/components/main/patient/notificationsSettings/NotificationSettingDisplay.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";

const {
  "notifications/getNotificationsPreferences": getNotificationsPreferences,
  "notifications/configureNotificationsForPatient":
    configureNotificationsForPatient,
} = mapActions();

const queryClient = useQueryClient();

// Fetch data using vue-query
const {
  isPending,
  data: preferencesData,
  refetch,
} = useQuery({
  queryKey: ["notificationsPreferences"],
  queryFn: () => getNotificationsPreferences(),
});

// Mutation for updating preferences
const { mutate: updatePreferences, isLoading: isUpdating } = useMutation({
  mutationFn: async (preferences) => {
    // Use the configured action to send the update request
    return await configureNotificationsForPatient(preferences);
  },
  onSuccess: (response) => {
    push.success(
      response.message || "Notification preferences updated successfully."
    );
    // Invalidate and refetch data to ensure the UI is up-to-date
    queryClient.invalidateQueries({
      queryKey: ["notificationsPreferences"],
    });
  },
  onError: (error) => {
    console.error("Failed to update notification preferences:", error);
    push.error("An error occurred while updating preferences.");
  },
});

// Computed property to transform API data into required format
const notificationSettings = computed(() => {
  // Return an empty array if no data is available
  if (!preferencesData.value?.preferences) return [];

  const preferences = preferencesData.value.preferences;

  // Safely access properties with optional chaining or fallback to defaults
  return [
    {
      title: "General Notifications",
      description:
        "Stay connected and informed with our notification settings. Choose how you'd like to receive updates.",
      parentKey: null, // No parent for this section
      items: [
        {
          name: "Push Notifications",
          description: "Get real-time alerts and updates on your device.",
          key: "push_notifications",
          enabled: preferences.notifications?.push?.enabled || false,
        },
        {
          name: "Email Notifications",
          description:
            "Receive email notifications in your mailbox for important updates and reminders.",
          key: "email_notifications",
          enabled: preferences.notifications.email.enabled || false,
        },
        {
          name: "SMS Notifications",
          description:
            "Receive text messages for urgent alerts and notifications, ensuring you're always in the know.",
          key: "sms_notifications",
          enabled: preferences.notifications.sms.enabled || false,
        },
        {
          name: "Alerts",
          description: "Get alert notifications for critical health updates.",
          key: "alerts",
          enabled: preferences.alerts.enabled || false,
        },
        {
          name: "Reminders",
          description: "Never miss an appointment with timely reminders.",
          key: "general_reminders",
          enabled: preferences?.general?.enabled || false,
        },
      ],
    },
    {
      title: "Email Notifications",
      description:
        "Receive email notifications in your mailbox for important updates and reminders, ensuring you're always in the know.",
      parentKey: "email_notifications",
      items: [
        {
          name: "Appointment Reminders",
          description:
            "Receive reminders for appointments, deadlines, or notifications about upcoming events.",
          key: "email_appointment_alerts",
          enabled: preferences.notifications.email.appointment_alerts || false,
        },
        {
          name: "Security Alerts",
          description:
            "Notifications about suspicious account activity or login attempts.",
          key: "email_security_alerts",
          enabled: preferences.notifications.email.security_alerts || false,
        },
        {
          name: "Service Outages",
          description:
            "Alerts about service disruptions or maintenance schedules.",
          key: "email_service_outage_alerts",
          enabled:
            preferences.notifications.email.service_outage_alerts || false,
        },
        {
          name: "Emergency Alerts",
          description:
            "Critical alerts related to emergencies or important updates.",
          key: "email_emergency_alerts",
          enabled: preferences.notifications.email.emergency_alerts || false,
        },
        {
          name: "Account Link Request Emails",
          description:
            "Receive notifications in your mailbox when someone sends a request to link accounts, keeping you in the know and responding promptly.",
          key: "email_link_account_alerts",
          enabled: preferences.notifications.email.link_account_alerts || false,
        },
      ],
    },
    {
      title: "SMS Notifications",
      description:
        "Receive text messages for urgent alerts and notifications, ensuring you're always in the know.",
      parentKey: "sms_notifications",
      items: [
        {
          name: "Security Alerts",
          description:
            "Notifications about suspicious account activity or login attempts.",
          key: "sms_security_alerts",
          enabled: preferences.notifications.sms.security_alerts || false,
        },
        {
          name: "Appointment Reminders SMS",
          description: "Reminders for scheduled appointments or events.",
          key: "sms_appointment_alerts",
          enabled: preferences.notifications.sms.appointment_alerts || false,
        },
        {
          name: "Service Outages",
          description:
            "Alerts about service disruptions or maintenance schedules.",
          key: "sms_service_outage_alerts",
          enabled: preferences.notifications.sms.service_outage_alerts || false,
        },
        {
          name: "Emergency Alerts",
          description:
            "Critical alerts related to emergencies or important updates.",
          key: "sms_emergency_alerts",
          enabled: preferences.notifications.email.emergency_alerts || false,
        },
        {
          name: "Account Link Request Emails",
          description:
            "Receive notifications in your mailbox when someone sends a request to link accounts, keeping you in the know and responding promptly.",
          key: "sms_link_account_alerts",
          enabled: preferences.notifications.email.link_account_alerts || false,
        },
      ],
    },
    {
      title: "Alerts Notifications",
      parentKey: "alerts",
      description:
        "Get urgent alert notifications for critical health updates and important reminders.",
      items: [
        {
          name: "Blood Sugar Alert",
          description:
            "Receive clear, actionable information based on your blood sugar readings, keeping you informed about your health status.",
          key: "glucose_general_alerts",
          enabled: preferences.alerts.glucose_general_alerts || false,
        },
        {
          name: "Blood Sugar Alert for Linked Account",
          description:
            "Receive alerts when a linked account's blood sugar readings stay informed about their health status.",
          key: "connection_alerts",
          enabled: preferences.alerts.connection_alerts || false,
        },
        {
          name: "Account Updates",
          description:
            "Stay informed with updates about your account or profile.",
          key: "account_updates_alerts",
          enabled: preferences.alerts.account_updates_alerts || false,
        },
      ],
    },
    {
      title: "Announcement Notifications",
      description:
        "Get urgent alert notifications for critical health updates and important reminders.",
      parentKey: "push_notifications",
      items: [
        {
          name: "Health Tips",
          description:
            "Discover new health tips and articles to boost your wellness.",
          key: "health_tips_alerts",
          enabled: preferences.notifications.push.health_tips_alerts || false,
        },
        {
          name: "Articles",
          description:
            "Stay updated with informative health articles and insights.",
          key: "articles_alerts",
          enabled: preferences.notifications.push.articles_alerts || false,
        },
        {
          name: "System Updates",
          description:
            "Keep your system running smoothly with the latest system updates.",
          key: "system_updates_alerts",
          enabled:
            preferences.notifications.push.system_updates_alerts || false,
        },
        {
          name: "Food Reminder",
          description:
            "Stay on track with your meal schedule and dietary goals with timely reminders.",
          key: "food_reminders",
          enabled: preferences.notifications.push.food_reminders || false,
        },
        {
          name: "Water Reminder",
          description: "Stay hydrated throughout the day with water reminders.",
          key: "water_reminders",
          enabled: preferences.notifications.push.water_reminders || false,
        },
        {
          name: "Exercise Reminder",
          description: "Stay active with timely exercise reminders.",
          key: "exercise_reminders",
          enabled: preferences.notifications.push.exercise_reminders || false,
        },
        {
          name: "Medications Reminder",
          description: "Stay on track with timely medication reminders.",
          key: "medications_reminders",
          enabled:
            preferences.notifications.push.medications_reminders || false,
        },
        {
          name: "Glucose Reminder",
          description: "Keep your health in check with timely glucose reminders.",
          key: "glucose_reminders",
          enabled: preferences.notifications.push.glucose_reminders || false,
        },
      ],
    },
  ];
});

const updateNotification = (sectionIndex, { index, value }) => {
  if (!preferencesData.value) return;

  const section = notificationSettings.value[sectionIndex];
  const item = section.items[index];
  const preferences = preferencesData.value.preferences;

  let payload = {};

  // Check if the section has a parentKey
  if (section.parentKey) {
    // Ensure parent is enabled when toggling a child notification
    payload[section.parentKey] = true;
  }

  // Add the specific item key to the payload
  payload[item.key] = value;

  // Send the payload for update
  updatePreferences(payload);
};

const determineDisabledState = (section) => {
  const preferences = preferencesData.value?.preferences;
  if (!preferences) return true;

  // Disable toggles during update process
  if (isUpdating) return true;

  // If no parentKey, the section is always enabled
  if (!section.parentKey) return false;

  // Split the parentKey to handle nested objects
  const [parentType, parentSubType] = section.parentKey.split("_");

  // more robust null checking
  if (!preferencesData.value.preferences.notifications) return true;

  // Handle different nested structures
  if (parentType === "email" || parentType === "sms") {
    return (
      !preferencesData.value.preferences.notifications[parentType]?.enabled ||
      false
    );
  }

  if (parentType === "push") {
    return (
      !preferencesData.value.preferences.notifications[parentType]?.enabled ||
      false
    );
  }

  if (parentType === "alerts") {
    return !preferencesData.value.preferences.alerts?.enabled || false;
  }

  return true;
};
</script>

<style scoped></style>
