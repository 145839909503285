<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      ref="scrollContainer"
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar"
      style="height: 80vh"
      @scroll="handleScroll"
    >
      <div class="w-full px-2 md:px-5 py-6 rounded-xl">
        <NotificationTabs
          :filter="filter"
          @setNotificationFilter="setTypeFilter"
        />

        <div
          v-if="isLoading && currentPage[filter] === 1"
          class="w-full mx-auto h-64 flex justify-center items-center"
        >
          <div class="w-5">
            <Spinner />
          </div>
        </div>

        <div
          v-else-if="filteredNotifications.length === 0"
          class="w-full h-64 flex justify-center items-center"
        >
          <div
            class="flex flex-col justify-center items-center py-10 space-y-4"
          >
            <div
              class="w-[120px] h-[120px] bg-WhiteLilac p-6 rounded-full flex justify-center items-center"
            >
              <img :src="bellRingingIcon" alt="bell ringing icon" />
            </div>
            <div class="text-Cinder text-center">
              <p class="text-2xl font-bold mb-4">You're All Caught Up!</p>
              <p>You have no new notifications at the moment.</p>
              <p class="text-base">
                Check back later for updates on your appointments, health
                reminders, and personalized health tips.
              </p>
            </div>
          </div>
        </div>

        <div v-else>
  <div v-for="(period, index) in periodsWithData" :key="index">
    <h2 class="text-lg font-semibold mb-4 text-center text-SkyGrey">
      {{ period.title }}
    </h2>
    <div class="overflow-x-auto overflow-y-visible no-scrollbar">
      <NotificationCard
        v-for="notification in filteredNotificationsByPeriod(period)"
        :key="notification.id"
        :notification="notification"
        :deleteNotification="deleteNotification"
        :isLoading="loadingState[notification.id] || false"
      />
    </div>
  </div>
  <div
    v-if="isLoading && currentPage[filter] > 1"
    class="w-full flex justify-center items-center py-4"
  >
    <div class="w-5">
      <Spinner />
    </div>
  </div>
</div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { mapActions, mapState } from "@/hooks/mapStore";
import { push } from "notivue";
import Spinner from "@/components/icons/SpinnerIcon.vue";
import NotificationTabs from "@/components/main/patient/notifications/NotificationTabs.vue";
import NotificationCard from "@/components/main/patient/notifications/NotificationCard.vue";
import bellRingingIcon from "@/assets/icons/bell-ringing.svg";

const { notifications } = mapState();
const {
  "notifications/loadMoreNotifications": loadMoreNotifications,
  "notifications/fetchAllNotifications": fetchAllNotifications,
  "notifications/deleteNotification" : deleteNotifications
} = mapActions();

const route = useRoute();
const router = useRouter();

const appointmentTypes = ["all", "alert", "announcement", "reminder"];

let routeQuery = route.query.type;

if (!appointmentTypes.includes(routeQuery)) {
  routeQuery = "all";
}

const filter = ref(routeQuery);
const isLoading = computed(() => notifications.value.isLoading);
const currentPage = computed(() => notifications.value.page);
const timePeriods = ref([
  { key: "today", title: "Today" },
  { key: "yesterday", title: "Yesterday" },
  { key: "last_week", title: "Last Week" },
  { key: "last_month", title: "Last Month" },
  { key: "earlier_months", title: "Earlier Months" },
]);

const loadingState = ref({});




const initializeNotifications = async () => {
  await fetchAllNotifications();
};

const filteredNotifications = computed(() => {
  switch (filter.value) {
    case "alert":
      return notifications.value.alerts;
    case "announcement":
      return notifications.value.announcements;
    case "reminder":
      return notifications.value.reminders;
    default:
      return notifications.value.allNotifications;
  }
});



const filteredNotificationsByPeriod = (period) => {
  const now = new Date();
  let periodStart = new Date(now);
  let periodEnd = new Date(now);

  switch (period.key) {
    case "today":
      periodStart.setHours(0, 0, 0, 0);
      break;
    case "yesterday":
      periodStart.setDate(periodStart.getDate() - 1);
      periodStart.setHours(0, 0, 0, 0);
      periodEnd = new Date(periodStart);
      periodEnd.setHours(23, 59, 59, 999);
      break;
    case "last_week":
      periodStart.setDate(periodStart.getDate() - 7);
      periodStart.setHours(0, 0, 0, 0);
      periodEnd.setDate(periodEnd.getDate() - 2);
      periodEnd.setHours(23, 59, 59, 999);
      break;
    case "last_month":
      periodStart.setDate(periodStart.getDate() - 30); 
      periodStart.setHours(0, 0, 0, 0);
      periodEnd.setDate(periodEnd.getDate() - 8); 
      periodEnd.setHours(23, 59, 59, 999);
      break;
      case "earlier_months":
      periodEnd.setDate(periodEnd.getDate() - 30);
      periodStart = new Date(0); 
      break;
    default:
      return [];
  }

  return filteredNotifications.value.filter((notification) => {
    const notificationDate = new Date(notification.created_at);
    return notificationDate >= periodStart && notificationDate <= periodEnd;
  });
};




const deleteNotification = async (notification_id) => {
  loadingState.value[notification_id] = true;
  try {
    await deleteNotifications(notification_id);
    push.success("Notification deleted successfully.");
  } catch (error) {
    push.error("Failed to delete the notification. Please try again.");
  } finally {
    loadingState.value[notification_id] = false;
  }
};




const periodsWithData = computed(() => {
  return timePeriods.value.filter(
    (period) => filteredNotificationsByPeriod(period).length > 0
  );
});




const setTypeFilter = (type) => {
  filter.value = type;
  router.push(`/patient/notifications?type=${type}`);
};

function debounce(func, wait = 300) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}


const scrollContainer = ref(null);

const handleScroll = debounce(async () => {
  if (!scrollContainer.value) return;

  const { scrollTop, scrollHeight, clientHeight } = scrollContainer.value;

  if (
    scrollTop + clientHeight >= scrollHeight - 100 &&
    !isLoading.value &&
    filteredNotifications.value.length < notifications.value.total[filter.value] // Ensure more items are available
  ) {
    await loadMoreNotifications({ type_filter: filter.value });
  }
}, 300); 







onMounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.addEventListener("scroll", handleScroll);
  }
});

onUnmounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener("scroll", handleScroll);
  }
});

watchEffect(() => {
  initializeNotifications();
});

</script>
