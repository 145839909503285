// import axios from "axios";

// const instance = axios.create({
//   withCredentials: true,
//   crossDomain: true,
//   baseURL: `${process.env.VUE_APP_BASE_URL}`,
// });

// export default instance;

import axios from "axios";
import router from "@/router";

const instance = axios.create({
  withCredentials: true,
  crossDomain: true,
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
});


// Response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Handle 401 errors for session expiration
    if (error.response && error.response.status === 401) {
      const errorMessage = error.response.data.message;

      if (errorMessage === "Session expired due to inactivity") {

        router.push("/auth/sign-in");
      }
    }

    // For other errors, reject the promise
    return Promise.reject(error);
  }
);

export default instance;

