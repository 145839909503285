<template>
  <ModalWrapper>
    <div
      class="max-w-[900px] w-full p-6 md:p-12 rounded-[38px] bg-white relative"
    >
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          {{ selectedTestType }}
        </h3>
        <div class="cursor-pointer" @click="closeManualTestModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <div class="max-w-3xl w-full mx-auto flex flex-col gap-[50px]">
        <!-- Test Value Section -->
        <div
          v-if="
            selectedTestType !== 'Malaria' &&
            selectedTestType !== 'Widal Test' &&
            selectedTestType !== 'Hepatitis B' &&
            selectedTestType !== 'Voluntary Serology'
          "
          class="self-center w-full h-[226px] border-2 border-ClearBlue flex justify-center items-center rounded-xl"
        >
          <div
            class="flex gap-5 justify-center items-center"
            :class="{
              'w-[200px]': selectedTestType === 'Blood Pressure',
              'w-[154px]': selectedTestType !== 'Blood Pressure',
            }"
          >
            <!-- Conditional Inputs for Blood Pressure -->
            <template v-if="selectedTestType === 'Blood Pressure'">
              <div class="flex items-end gap-0">
                <input
                  v-model="systolicPressure"
                  :disabled="isAutoMode"
                  type="text"
                  class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-[60%] bg-transparent border-none outline-none"
                  placeholder="_ _"
                  @input="updatePressure"
                />
                <span class="text-ResolutionBlue text-2xl font-bold">/</span>
                <input
                  v-model="diastolicPressure"
                  :disabled="isAutoMode"
                  type="text"
                  class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-[60%] bg-transparent border-none outline-none"
                  placeholder="_ _"
                  @input="updatePressure"
                />
              </div>
            </template>
            <!-- Single Input for Other Tests -->
            <template v-else>
              <input
                v-model="testValue"
                :disabled="isAutoMode"
                type="text"
                class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-full bg-transparent border-none outline-none"
                placeholder="__"
                @input="updateTestValue"
              />
            </template>

            <div class="relative">
              <button
                v-if="!isAutoMode"
                @click="toggleDropdown"
                class="min-w-[100px] w-full flex items-center justify-between py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
                <img
                  :src="whiteDownArrowIcon"
                  alt="unit dropdown"
                  class="ml-1 w-4 h-4"
                  :class="{ 'rotate-180': isDropdownOpen }"
                />
              </button>
              <span
                v-else
                class="flex items-center justify-center gap-1 py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
              </span>

              <!-- Custom dropdown menu -->
              <div
                v-if="!isAutoMode && isDropdownOpen"
                class="absolute top-full left-0 mt-1 w-full bg-white border border-BlueChalk rounded-lg shadow-lg z-50"
              >
                <button
                  v-for="option in unitOptions"
                  :key="option"
                  @click="selectUnit(option)"
                  class="block w-full text-left px-4 py-2 text-sm text-ResolutionBlue hover:bg-BlueChalk"
                  :class="{ 'bg-BlueChalk': units === option }"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Range Indicator -->
        <div
          v-if="
            selectedTestType !== 'ECG' &&
            selectedTestType !== 'Malaria' &&
            selectedTestType !== 'Widal Test' &&
            selectedTestType !== 'Weight' &&
            selectedTestType !== 'Hepatitis B' &&
            selectedTestType !== 'Voluntary Serology'
          "
          class="w-full flex flex-col items-center"
        >
          <!-- Range indicator and markers -->
          <!-- Labels above the range -->
          <div class="w-full flex justify-between text-xs text-MistBlue">
            <span>Low</span>
            <span>Normal</span>
            <span>Average High</span>
            <span>High</span>
          </div>
          <div class="w-full h-4 relative">
            <!-- Color bars -->
            <div
              class="absolute bottom-0 w-full h-2 rounded-full overflow-hidden flex"
            >
              <div class="w-[25%] h-full bg-ResolutionBlue"></div>
              <div class="w-[25%] h-full bg-AsignGreen"></div>
              <div class="w-[25%] h-full bg-OrangePeel"></div>
              <div class="w-[25%] h-full bg-ArtyClickRed"></div>
            </div>
            <!-- Moving Circle -->
            <div
              class="absolute top-1 w-4 h-4 flex items-center justify-center rounded-full bg-white"
              :class="[rangeColors.border]"
              :style="{ left: `${rangeCirclePosition}%` }"
            >
              <div class="w-2 h-2 rounded-full" :class="[rangeColors.bg]"></div>
            </div>

            <!-- Vertical Markers -->
            <div
              class="absolute top-1 left-[25%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
            <div
              class="absolute top-1 left-[50%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
            <div
              class="absolute top-1 left-[75%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
          </div>
        </div>

        <!-- Reusable Component -->
        <TestTypeFields
          :type="selectedTestType"
          :isAutoMode="isAutoMode"
          :additionalFields="additionalFields"
          :setActiveTab="setActiveTab"
          :activeTab="activeTab"
          :widalTests="widalTests"
          :hepatitisResult="hepatitisResultObj"
          :hivResult="hivResultObj"
        />

        <!-- Date and Time Selection -->
        <div class="w-full flex items-center gap-10">
          <!-- Custom Date Input -->
          <div class="w-full cursor-pointer relative" @click="openDatePicker">
            <input
              type="date"
              ref="datePicker"
              v-model="selectedDate"
              :max="maxDate()"
              :disabled="isAutoMode"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer"
              :class="
                isAutoMode ? 'pointer-events-none' : 'pointer-events-auto'
              "
            />

            <div
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span>{{ formattedDate || "Choose date" }}</span>
              <img
                :src="calendarGrayIcon"
                alt="calendar icon"
                class="ml-2 w-5 h-5"
              />
            </div>
          </div>

          <!-- Custom Time Input -->
          <div class="w-full cursor-pointer relative" @click="openTimePicker">
            <input
              type="time"
              ref="timePicker"
              v-model="selectedTime"
              :disabled="isAutoMode"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer z-10"
              :class="
                isAutoMode ? 'pointer-events-none' : 'pointer-events-auto'
              "
            />
            <div
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span>{{ formattedTime || "Choose time" }}</span>
              <img
                :src="clockIcon"
                alt="clock icon"
                class="ml-2 w-5 h-5 pointer-events-none"
              />
            </div>
          </div>
        </div>

        <!-- Cancel and Save Buttons -->
        <div class="flex justify-center items-center gap-4 mt-8">
          <button
            @click="closeManualTestModal"
            class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
          >
            Cancel
          </button>
          <button
            v-if="!isAutoMode"
            @click="handleSubmit"
            :disabled="isPending || !isFormValid"
            :class="[
              'px-[52px] py-3 text-white rounded-full text-sm font-semibold',
              isPending || !isFormValid
                ? 'bg-[#E0E0E0] cursor-not-allowed'
                : 'bg-ResolutionBlue cursor-pointer',
            ]"
          >
            <span v-if="isPending">
              <LoadingSpinner />
            </span>
            <span v-else> Save </span>
          </button>
          <button
            v-else
            @click="handleDone"
            class="px-[52px] py-3 bg-ResolutionBlue text-white rounded-full text-sm font-semibold"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import {
  inject,
  ref,
  watch,
  computed,
  onMounted,
  onUnmounted,
  reactive,
} from "vue";
import { mapActions } from "@/hooks/mapStore";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { push } from "notivue";
import { testTypes } from "@/utils/mockData/testTypes";
import { validateInput } from "@/utils/manualTestInputValidation";
import ModalWrapper from "./ModalWrapper.vue";
import TestTypeFields from "../TestTypeFields.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { maxDate } from "@/utils/dateFormatter";
import Input from "@/components/main/ui/Input.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import whiteDownArrowIcon from "@/assets/icons/white-down-arrow.svg";
import calendarGrayIcon from "@/assets/icons/calendar-gray-icon.svg";
import clockIcon from "@/assets/icons/clock.svg";

const props = defineProps({
  selectedTestType: String,
  patientId: String,
  existingData: Object, // Optional data passed from the socket event
});

const queryClient = useQueryClient();

const { "test/submitTestByType": submitTestByType } = mapActions();

const activeTab = ref("beforeMeal");
const testValue = ref("");
const systolicPressure = ref("");
const diastolicPressure = ref("");
const selectedDate = ref(""); // Raw date value
const selectedTime = ref(""); // Raw time value
const formattedDate = ref(""); // Formatted date for display
const formattedTime = ref(""); // Formatted time for display
const units = ref(" ");
const unitOptions = ref([]);

const isDropdownOpen = ref(false);
// References for the hidden input elements
const datePicker = ref(null);
const timePicker = ref(null);
const hepatitisResultObj = reactive({
  value: "",
});
const hivResultObj = reactive({
  value: "",
});

// Computed property to determine if we're in auto mode
const isAutoMode = computed(() => !!props.existingData);

const openMealSelectModal = inject("openMealSelectModal");
const openTestSuccessfulModal = inject("openTestSuccessfulModal");
const closeManualTestModal = inject("closeManualTestModal");
const closeTakeTestModal = inject("closeTakeTestModal");

const additionalFields = reactive({
  systolic: null,
  diastolic: null,
  pulseRate: null,
  oxygenSaturation: null,
  fev1: null,
  analysis: null,
  malaria_note: null,
});

const widalTests = ref([
  { testName: "Typhi O (TO)", testResult: "" },
  { testName: "Typhi H (TH)", testResult: "" },
  { testName: "Paratyphi A,H (AH)", testResult: "" },
  { testName: "Paratyphi B,H (BH)", testResult: "" },
]);

const resetFields = () => {
  testValue.value = "";
  activeTab.value = "beforeMeal"
  systolicPressure.value = "";
  diastolicPressure.value = "";
  selectedDate.value = "";
  selectedTime.value = "";
  formattedDate.value = "";
  formattedTime.value = "";
  units.value = " ";
  additionalFields.systolic = null;
  additionalFields.diastolic = null;
  additionalFields.pulseRate = null;
  additionalFields.oxygenSaturation = null;
  additionalFields.fev1 = null;
  additionalFields.analysis = null;
  additionalFields.malaria_note = null;
  widalTests.value = widalTests.value.map((test) => ({
    ...test,
    testResult: "",
  }));
  hepatitisResultObj.value = "";
  hivResultObj.value = "";
};

const rangeCirclePosition = computed(() => {
  if (props.selectedTestType === "Blood Pressure") {
    // Custom logic for Blood Pressure ranges
    const systolic = parseInt(systolicPressure.value, 10) || 0;

    if (systolic < 90) return 10; // Low
    if (systolic >= 90 && systolic <= 120) return 30; // Normal
    if (systolic > 120 && systolic <= 140) return 60; // Average High
    return 90; // High
  } else {
    // General logic for other test types
    const value = parseInt(testValue.value, 10) || 0;
    if (value < 50) return 10; // Low
    if (value >= 50 && value <= 100) return 30; // Normal
    if (value > 100 && value <= 150) return 60; // Average High
    return 90; // High
  }
});

const rangeStatus = computed(() => {
  const position = rangeCirclePosition.value;

  if (position <= 25) return "Low";
  if (position > 25 && position <= 50) return "Normal";
  if (position > 50 && position <= 75) return "Average High";
  return "High";
});

const rangeColors = computed(() => {
  const position = rangeCirclePosition.value;

  // Define colors for each range
  if (position <= 25) {
    return {
      bg: "bg-ResolutionBlue",
      border: "border-2 border-ResolutionBlue",
    }; // Low
  } else if (position > 25 && position <= 50) {
    return { bg: "bg-AsignGreen", border: "border-2 border-AsignGreen" }; // Normal
  } else if (position > 50 && position <= 75) {
    return { bg: "bg-OrangePeel", border: "border-2 border-OrangePeel" }; // Average High
  } else {
    return { bg: "bg-ArtyClickRed", border: "border-2 border-ArtyClickRed" }; // High
  }
});

const handleDone = () => {
  closeManualTestModal();
  closeTakeTestModal();

  if (isAutoMode.value) {
    openMealSelectModal(props.existingData.glucose_level_id);
  } else {
    openTestSuccessfulModal();
  }
};

// Method to validate and update test value
const updateTestValue = (event) => {
  testValue.value = validateInput(event.target.value); // Use the utility function
};

// Method to change the active tab
const setActiveTab = (tab) => {
  if (!isAutoMode.value) {
    activeTab.value = tab;

    if (tab === "fasting") {
      additionalFields.beforeOrAfterMeals = undefined;
      additionalFields.fasting = 0;
    } else {
      additionalFields.fasting = undefined;
      additionalFields.beforeOrAfterMeals = tab === "beforeMeal" ? 0 : 1;
    }
  }
};

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const initializeUnits = () => {
  const selectedTest = testTypes.find(
    (test) => test.name === props.selectedTestType
  );
  if (selectedTest) {
    units.value = selectedTest.defaultUnit;
    unitOptions.value = selectedTest.unitOptions;
  }
};

watch(() => props.selectedTestType, initializeUnits, { immediate: true });

const selectUnit = (newUnit) => {
  units.value = newUnit;
  isDropdownOpen.value = false;
};

// Close dropdown when clicking outside
const closeDropdown = (event) => {
  if (isDropdownOpen.value && !event.target.closest(".relative")) {
    isDropdownOpen.value = false;
  }
};

const isFormValid = computed(() => {
  if (!formattedDate.value || !formattedTime.value) return false;

  if (props.selectedTestType === "Blood Pressure") {
    return systolicPressure.value && diastolicPressure.value;
  }

  if (["Temperature", "Weight"].includes(props.selectedTestType)) {
    return testValue.value;
  }

  if (props.selectedTestType === "Glucose") {
    return (
      testValue.value &&
      (activeTab.value === "fasting" ||
        (activeTab.value !== "fasting" &&
          additionalFields.beforeOrAfterMeals !== undefined))
    );
  }
  if (props.selectedTestType === "Lungs capacity") {
    return testValue.value && additionalFields.fev1;
  }

  if (props.selectedTestType === "ECG") {
    return testValue.value && additionalFields.analysis;
  }

  if (props.selectedTestType === "Malaria") {
    return additionalFields.malaria_note;
  }

  if (props.selectedTestType === "Spo2") {
    return additionalFields.pulseRate && additionalFields.perfusionIndex;
  }

  if (props.selectedTestType === "Spo2") {
    const pulseRate = additionalFields.pulseRate;
    if (pulseRate < 30 || pulseRate > 220) {
      push.error("Pulse Rate must be between 30 and 220.");
      return;
    }
  }

  if (props.selectedTestType === "Widal Test") {
    return widalTests.value.every((test) => test.testResult);
  }

  if (props.selectedTestType === "Hepatitis B") {
    return (
      hepatitisResultObj.value === "HEP B+" ||
      hepatitisResultObj.value === "HEP B-"
    );
  }

  if (props.selectedTestType === "Voluntary Serology") {
    return hivResultObj.value === "HIV +" || hivResultObj.value === "HIV -";
  }

  return true;
});

// Method to open hidden date picker
const openDatePicker = () => {
  if (!isAutoMode.value) {
    datePicker.value.click();
  }
};

// Method to open hidden time picker
const openTimePicker = () => {
  if (!isAutoMode.value) {
    timePicker.value.click();
  }
};

// Method to handle date change
const handleDateChange = () => {
  if (selectedDate.value) {
    const date = new Date(selectedDate.value);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    formattedDate.value = `${day}-${month}-${year}`;
  }
};

// Method to handle time change
const handleTimeChange = () => {
  if (selectedTime.value) {
    const time = new Date(`1970-01-01T${selectedTime.value}:00`);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    formattedTime.value = `${hours}:${minutes}:${seconds}`;
  }
};

// Mutation for submitting test result
const { mutate: submitTestMutation, isPending } = useMutation({
  mutationFn: async ({ testType, payload }) => {
    return await submitTestByType({ testType, payload });
  },
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: ["bloodGlucoseRecords"],
    });
    // Clear fields after successful submission
    resetFields();
    closeManualTestModal();
    closeTakeTestModal();
    openTestSuccessfulModal();
  },
  onError: (error) => {
    const errorMessage =
      error.response?.data?.message ||
      "An error occurred while submitting the test result.";
    push.error(errorMessage);
  },
});

// handleSubmit function using mutations
const handleSubmit = () => {
  if (!isFormValid.value) {
    push.error("Please fill all fields before submitting.");
    return;
  }

  const payloadGenerators = {
    Glucose: () => {
      const payload = {
        Check_Time: `${formattedDate.value} ${formattedTime.value}`,
        units: units.value || "mg/dL",
        Check_Data: {
          Blood_sugar_value: testValue.value,
          ...(activeTab.value === "fasting"
            ? { Fasting: 0 }
            : { Before_or_after_meals: additionalFields.beforeOrAfterMeals }),
        },
      };

      return payload;
    },
    "Blood Pressure": () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      status: rangeStatus.value,
      systolic: systolicPressure.value,
      diastolic: diastolicPressure.value,
      pulseRate: additionalFields.pulseRate.toString(),
      padDetected: additionalFields.padDetected,
      units: units.value || "mmHg",
    }),
    Temperature: () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      status: rangeStatus.value,
      temperature: `${testValue.value} ${units.value}`,
      description: additionalFields.description,
    }),
    Weight: () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      weight: `${testValue.value} ${units.value}`,
    }),
    ECG: () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      heartRate: `${testValue.value} ${units.value}`,
      analysis: additionalFields.analysis,
    }),
    "Lungs capacity": () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      fev1: additionalFields.fev1,
      pef: `${testValue.value} ${units.value}`,
    }),
    Spo2: () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      pulseRate: parseFloat(additionalFields.pulseRate),
      oxygenSaturation: parseFloat(testValue.value) || 0,
      perfusionIndex: additionalFields.perfusionIndex || 0.0,
    }),
    Malaria: () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      malaria_note: additionalFields.malaria_note,
    }),
    "Widal Test": () => ({
      Widal: widalTests.value.map((test) => ({
        test_name: test.testName,
        test_result: test.testResult,
      })),
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
    }),
    "Hepatitis B": () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      Hepatitis_B:
        hepatitisResultObj.value === "HEP B+" ? "Positive" : "Negative",
    }),
    "Voluntary Serology": () => ({
      Check_Time: `${formattedDate.value} ${formattedTime.value}`,
      Hiv: hivResultObj.value === "HIV +" ? "Positive" : "Negative",
    }),
  };

  const payloadGenerator = payloadGenerators[props.selectedTestType];

  if (!payloadGenerator) {
    push.error("Unsupported test type.");
    return;
  }

  const payload = payloadGenerator();

  // Call the mutation function with the correct payload
  submitTestMutation({ testType: props.selectedTestType, payload });
};

const populateFormWithExistingData = () => {
  const data = props.existingData;
  testValue.value = data.glucose_level;
  activeTab.value = data.before_meal ? "beforeMeal" : "afterMeal";
  units.value = data.units || "mg/dL";

  if (data.check_time) {
    const dateTime = new Date(data.check_time);

    // Format date as YYYY-MM-DD for the date input
    selectedDate.value = dateTime.toISOString().split("T")[0];

    // Format time as HH:mm for the time input
    selectedTime.value = dateTime.toTimeString().slice(0, 5);

    handleDateChange();
    handleTimeChange();
  }
};

watch(
  () => props.existingData,
  (newData) => {
    if (newData) {
      populateFormWithExistingData();
    }
  },
  { immediate: true, deep: true }
);

// Watch for changes in selectedDate and selectedTime
watch(selectedDate, handleDateChange);
watch(selectedTime, handleTimeChange);

onMounted(() => {
  document.addEventListener("click", closeDropdown);
});

onUnmounted(() => {
  document.removeEventListener("click", closeDropdown);
});
</script>

<style scoped>
.input-with-dashes::placeholder {
  text-align: center;
  color: #011d7e;
}
</style>
