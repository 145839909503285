import axios from "@/api";
import { io } from "socket.io-client";

export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      prescriptions: [],
      errorMessage: "",
      healthCenters: [],
      medicalDevices: [],
      linkedPatientDevices: [],
      singleMedicalDevice: null,
      socket: null,
    };
  },

  getters: {
    getPrescriptions: (state) => state.prescriptions,
    getLoadingState: (state) => state.loading,
    getErrorMessage: (state) => state.errorMessage,
    getMedicalDevices: (state) => state.medicalDevices,
    getLinkedPatientDevices: (state) => state.linkedPatientDevices,
    getSingleMedicalDevice: (state) => state.singleMedicalDevice,
  },

  mutations: {
    SET_PRESCRIPTIONS(state, prescriptions) {
      state.prescriptions = prescriptions;
    },
    SET_LOADING_STATE(state, loadingState) {
      state.loading = loadingState;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    setHealthCenters(state, payload) {
      state.healthCenters = payload;
    },
    setMedicalDevices(state, devices) {
      state.medicalDevices = devices;
    },
    setLinkedPatientDevices(state, devices) {
      state.linkedPatientDevices = devices;
    },
    setSingleMedicalDevice(state, device) {
      state.singleMedicalDevice = device;
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
  },

  actions: {
    async fetchPrescriptions({ commit }) {
      try {
        commit("SET_LOADING_STATE", true);
        commit("SET_PRESCRIPTIONS", []);
        await axios
          .all([
            axios.get("prescriptions/internal"),
            axios.get("prescriptions/external"),
          ])
          .then(
            axios.spread((internalPrescriptions, externalPrescriptions) => {
              commit("SET_PRESCRIPTIONS", [
                ...internalPrescriptions.data.data,
                ...externalPrescriptions.data.data,
              ]);
              commit("SET_LOADING_STATE", false);
            })
          );
      } catch (error) {
        const {
          response: {
            data: { errorMessage },
          },
        } = error;
        commit("SET_ERROR_MESSAGE", errorMessage);
        commit("SET_LOADING_STATE", false);
        commit("SET_PRESCRIPTIONS", []);
      }
    },
    async fetchAllHealthCenters({ commit }) {
      try {
        const response = await axios.get("/data/health_centres");
        commit("setHealthCenters", response.data.posts);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async submitTestByType(_, { testType, payload }) {

      const endpoints = {
        Glucose: "/data/patient/glucose_level_manual",
        "Blood Pressure": "/data/patient/blood_pressure/manual_input",
        Temperature: "/data/patient/temperature/manual_input",
        Weight: "/data/patient/weight/manual_input",
        ECG: "/data/patient/ecg/manual_input",
        "Lungs capacity": "/data/patient/lung_capacity/manual_input",
        Spo2: "/data/patient/heart_rate/manual_input",
        Malaria: "/data/patient/malaria/manual_input",
        "Widal Test": "/data/patient/widal/manual_input",
        "Hepatitis B": "/data/patient/hepatitis/manual_input",
        "Voluntary Serology": "/data/patient/hiv/manual_input",
      };

      const payloadGenerators = {
        Glucose: (data) => {
          const payload = {
            Check_Time: data.Check_Time,
            Check_Data: {
              Blood_sugar_value: `${data.Check_Data.Blood_sugar_value} ${data.units}`,
            }
          };

          // Conditionally add fields only if they are not null
          if (data.Check_Data.Before_or_after_meals === null || data.Check_Data.Before_or_after_meals === undefined) {
            payload.Check_Data.Fasting = parseInt(data.Check_Data.Fasting, 10);
          } else {
            payload.Check_Data.Before_or_after_meals = parseInt(data.Check_Data.Before_or_after_meals, 10);
          }

          return payload;
        },

        "Blood Pressure": (data) => ({
          Check_Time: data.Check_Time,
          Status: data.status || "Normal",
          Check_Data: {
            Systolic_Blood_Pressure: `${data.systolic} ${data.units || "mmHg"}`,
            Diastolic_Blood_Pressure: `${data.diastolic} ${data.units || "mmHg"}`,
            Pulse_Rate: data.pulseRate.toString(),
            Pad_Detected: data.padDetected,
          },
        }),
        Temperature: (data) => ({
          Check_Time: data.Check_Time,
          Status: data.status || "normal",
          Check_Data: {
            Temperature: `${data.temperature}`,
            Description: data.description,
          },
        }),
        Weight: (data) => ({
          Check_Time: data.Check_Time,
          Check_Data: {
            Weight: `${data.weight}`,
          },
        }),
        ECG: (data) => ({
          Check_Time: data.Check_Time,
          Check_Data: {
            Heart_Rate: `${data.heartRate}`,
            Analysis: data.analysis,
          },
        }),
        "Lungs capacity": (data) => ({
          Check_Time: data.Check_Time,
          Check_Data: {
            FEV1: `${data.fev1} L`,
            PEF: `${data.pef}`,
          },
        }),
        Spo2: (data) => ({
          Check_Time: data.Check_Time,
          Pulse_Rate: data.pulseRate,
          Check_Data: {
            Oxygen_Saturation: `${data.oxygenSaturation} SpO2`,
            Perfusion_Index: `${data.perfusionIndex} PI`,
          },
        }),
        Malaria: (data) => ({
          Check_Time: data.Check_Time,
          Malaria_Parasite_Note: data.malaria_note,
        }),
        "Widal Test": (data) => ({
          Widal: data.Widal,
          Check_Time: data.Check_Time,
        }),
        "Hepatitis B": (data) => ({
          Check_Time: data.Check_Time,
          Hepatitis_B: data.Hepatitis_B,
        }),
        "Voluntary Serology": (data) => ({
          Check_Time: data.Check_Time,
          Hiv: data.Hiv,
        }),
      };

      if (!endpoints[testType] || !payloadGenerators[testType]) {
        throw new Error(`Unsupported test type: ${testType}`);
      }

      const endpoint = endpoints[testType];
      const body = payloadGenerators[testType](payload);

      try {
        await axios.post(endpoint, body);
      } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to submit test");
      }
    },


    async editManualTest(_, { testType, testTypeId, payload }) {
      const endpoints = {
        'Blood Glucose': `/data/patient/glucose_level/${testTypeId}`,
        'Blood Pressure': `/data/patient/blood_pressure/${testTypeId}`,
        'Heart Rate': `/data/patient/heart_rate/${testTypeId}`,
        'Weight': `/data/patient/weight/${testTypeId}`,
        'Temperature': `/data/patient/temperature/${testTypeId}`,
        'Lungs Capacity': `/data/patient/lung_capacity/${testTypeId}`,
        'ECG': `/data/patient/ecg/${testTypeId}`,
        'Malaria': `/data/patient/malaria/${testTypeId}`,
        'Widal': `/data/patient/widal/${testTypeId}`,
        'Hepatitis_B': `/data/patient/hepatitis/${testTypeId}`,
        'HIV': `/data/patient/hiv/${testTypeId}`,
      };

      // Define payload generators for each test type
      const payloadGenerators = {
        'Blood Glucose': (data) => ({
          Check_Time: data.Check_Time,
          Check_Data: {
            Blood_sugar_value: `${data.testValue} ${data.units}`,
            Before_or_after_meals: data.beforeOrAfterMeals,
            Fasting: data.additionalFields.fasting ? 1 : 0
          }
        }),

        'Blood Pressure': (data) => ({
          Check_Time: data.Check_Time,
          Status: data.status,
          Check_Data: {
            Systolic_Blood_Pressure: `${data.systolicPressure} ${data.units || "mmHg"}`,
            Diastolic_Blood_Pressure: `${data.diastolicPressure} ${data.units || "mmHg"}`,
            Pulse_Rate: data.additionalFields.pulseRate.toString(),
            Pad_Detected: data.additionalFields.padDetected
          }
        }),

        'Heart Rate': (data) => ({
          Check_Time: data.Check_Time,
          Pulse_Rate: data.additionalFields.pulseRate,
          Check_Data: {
            Oxygen_Saturation: `${data.testValue} SpO2`,
            Perfusion_Index: `${data.additionalFields.perfusionIndex} PI`
          }
        }),

        'Weight': (data) => ({
          Check_Time: data.Check_Time,
          Check_Data: {
            Weight: `${data.testValue} ${data.units}`
          }
        }),

        'Temperature': (data) => ({
          Check_Time: data.Check_Time,
          Status: data.additionalFields.status || "normal",
          Check_Data: {
            Temperature: `${data.testValue} ${data.units}`,
            Description: data.additionalFields.description
          }
        }),

        'Lungs Capacity': (data) => ({
          Check_Time: data.Check_Time,
          Check_Data: {
            FEV1: `${data.additionalFields.fev1} L`,
            PEF: `${data.testValue} L/min`
          }
        }),

        'ECG': (data) => ({
          Check_Time: data.Check_Time,
          Check_Data: {
            Heart_Rate: `${data.testValue} bpm`,
            Analysis: data.additionalFields.analysis
          }
        }),

        'Malaria': (data) => ({
          Check_Time: data.Check_Time,
          Malaria_Parasite_Note: data.additionalFields.malaria_note
        }),

        'Widal': (data) => ({
          Widal: data.widalTests.map(test => ({
            test_name: test.testName,
            test_result: test.testResult
          })),
          Check_Time: data.Check_Time
        }),

        'Hepatitis_B': (data) => ({
          Check_Time: data.Check_Time,
          Hepatitis_B: data.hepatitisResult.value === "HEP B+" ? "Positive" : "Negative"
        }),

        'HIV': (data) => ({
          Check_Time: data.Check_Time,
          Hiv: data.hivResult.value === "HIV +" ? "Positive" : "Negative"
        })
      };

      // Validate test type
      if (!endpoints[testType] || !payloadGenerators[testType]) {
        throw new Error(`Unsupported test type: ${testType}`);
      }

      // Execute the request
      await axios.put(endpoints[testType], payloadGenerators[testType](payload));
    },

    async deleteManualTest(_, { testType, testTypeId }) {
      const endpoints = {
        'Blood Glucose': `/data/patient/glucose_level/${testTypeId}`,
        'Blood Pressure': `/data/patient/blood_pressure/${testTypeId}`,
        'Heart Rate': `/data/patient/heart_rate/${testTypeId}`,
        'Weight': `/data/patient/weight/${testTypeId}`,
        'Temperature': `/data/patient/temperature/${testTypeId}`,
        'Lungs Capacity': `/data/patient/lung_capacity/${testTypeId}`,
        'ECG': `/data/patient/ecg/${testTypeId}`,
        'Malaria': `/data/patient/malaria/${testTypeId}`,
        'Widal': `/data/patient/widal/${testTypeId}`,
        'Hepatitis_B': `/data/patient/hepatitis/${testTypeId}`,
        'HIV': `/data/patient/hiv/${testTypeId}`,
      };

      if (!endpoints[testType]) {
        throw new Error(`Unsupported test type for deletion: ${testType}`);
      }


      try {
        await axios.delete(endpoints[testType]);
        return { success: true }; // ✅ Return a success response
      } catch (error) {
        return { success: false, message: error.response?.data?.message || `Failed to delete ${testType} test` };
      }

    },

    // submit test for another patient
    async submitTestResultForPatient(_, { patientId, payload }) {
      try {
        await axios.post(`/data/patient/connected/manual_input/${patientId}`, {
          Check_Time: payload.Check_Time,
          Check_Data: {
            Blood_sugar_value: `${payload.bloodSugar} ${payload.units || "mg/dL"
              }`,
            Before_or_after_meals: parseInt(payload.beforeOrAfterMeals, 10),
          },
        });
      } catch (error) {
        throw error;
      }
    },

    // Fetch medical devices
    async getMedicalDevices({ commit, rootGetters }) {
      try {
        const response = await axios.get("/data/patient/medical_device");
        const devices = response.data.medical_devices || []; // Add fallback
        commit("setMedicalDevices", devices);
        return { medical_devices: devices }; // Explicitly return the data
      } catch (error) {
        commit("SET_ERROR_MESSAGE", error.message || "Failed to fetch devices");
        return { medical_devices: [] }; // Return empty array on error
      }
    },

    async getLinkedPatientDevices({ commit }, connectedPatientId) {
      try {
        const response = await axios.get(
          `/data/patient/connected/device/${connectedPatientId}`
        );
        commit("setLinkedPatientDevices", response.data.devices);
      } catch (error) {
        commit(
          "SET_ERROR_MESSAGE",
          error.message || "Failed to fetch linked patient devices"
        );
      }
    },

    //  fetch single medical device by ID
    async getSingleMedicalDevice({ commit }, medicalDeviceId) {
      try {
        const response = await axios.get(
          `/data/patient/medical_device/${medicalDeviceId}`
        );
        commit("setSingleMedicalDevice", response.data);
        return response.data;
      } catch (error) {
        commit(
          "SET_ERROR_MESSAGE",
          error.message || "Failed to fetch medical device"
        );
        throw error;
      }
    },

    // attach meal select
    async attachMealSelect({ commit }, { glucoseLevelId, beforeMeal }) {
      await axios.patch(
        `/data/patient/patch/before_meal/${glucoseLevelId}`,
        {
          before_meal: beforeMeal,
        }
      );
    },

    async editATestForLinkedPatient(_, { glucoseLevelId, patientId, payload }) {
      try {
        await axios.put(
          `/data/patient/connected/glucose_level/${glucoseLevelId}/${patientId}`,
          {
            Check_Time: payload.Check_Time,
            Check_Data: {
              Blood_sugar_value: `${payload.bloodSugar} ${payload.units || "mg/dl"
                }`,
              Before_or_after_meals: parseInt(payload.beforeOrAfterMeals, 10),
            },
          }
        );
      } catch (error) {
        throw error;
      }
    },

    async deleteATestForLinkedPatient(_, { testType, testTypeId, patientId }) {
      const endpoints = {
        'Blood Glucose': `/data/patient/connected/glucose_level/${testTypeId}/${patientId}`,
        'Blood Pressure': `/data/patient/connected/blood_pressure/${testTypeId}/${patientId}`,
        'Heart Rate': `/data/patient/connected/heart_rate/${testTypeId}/${patientId}`,
        'Weight': `/data/patient/connected/weight/${testTypeId}/${patientId}`,
        'Temperature': `/data/patient/connected/temperature/${testTypeId}/${patientId}`,
        'Lungs Capacity': `/data/patient/connected/lung_capacity/${testTypeId}/${patientId}`,
        'ECG': `/data/patient/connected/ecg/${testTypeId}/${patientId}`,
        'Malaria': `/data/patient/malaria/${testTypeId}`,
        'Widal': `/data/patient/widal/${testTypeId}`,
        'Hepatitis_B': `/data/patient/hepatitis/${testTypeId}`,
        'HIV': `/data/patient/hiv/${testTypeId}`,
      };

      if (!endpoints[testType]) {
        throw new Error(`Unsupported test type for deletion: ${testType}`);
      }


      try {
        await axios.delete(endpoints[testType]);
        return { success: true }; // ✅ Return a success response
      } catch (error) {
        return { success: false, message: error.response?.data?.message || `Failed to delete ${testType} test` };
      }

    },

    async updateTestNotes(_, payload) {
      try {
        await axios.put(`/data/glucose_level/note/${payload.noteId}`, {
          note: payload.note,
        });
      } catch (error) {
        throw error;
      }
    },
  },
};
