<template>
  <div class="bg-WhiteLilac md:px-4 xl:px-6 rounded-xl">
    <div class="flex items-center justify-between h-[13%]">
      <p class="font-semibold">Vitals</p>
      <!-- <p class="text-xs underline">Edit</p> -->
    </div>

    <div class="grid grid-cols-2 pt-2 pb-4 gap-3.5 h-[87%]">
      <!-- Glucose Level -->
      <router-link
        :to="{ path: '/patient/blood-glucose', query: { testType: 'glucose' } }"
        class="h-full"
      >
        <div
          class="flex flex-col rounded-2xl justify-between bg-TitanWhite h-full px-5 py-3"
        >
          <div class="flex items-center justify-between">
            <p class="text-xs text-OlsoGrey font-semibold">Glucose Level</p>
            <img
              :src="glucoseIcon"
              class="p-2 bg-LavendaMist w-8 h-8 rounded-full"
              alt=""
            />
          </div>

          <div>
            <div
              class="text-lg md:text-2xl font-bold"
              :class="[
                glucoseData?.results[0]?.glucose_level <= normal
                  ? 'text-ForestGreen'
                  : glucoseData?.results[0]?.glucose_level > normal &&
                    glucoseData?.results[0]?.glucose_level < high
                  ? 'text-OrangePeel'
                  : glucoseData?.results[0]?.glucose_level >= high
                  ? 'text-ArtyClickRed'
                  : '',
              ]"
            >
              {{ glucoseData?.results[0]?.glucose_level || "---" }}
              <span class="text-xs font-semibold">
                {{ glucoseData?.results[0]?.units || "mg/dl" }}
              </span>
            </div>
          </div>
        </div>
      </router-link>

      <!-- Heart Rate -->
      <router-link
        :to="{
          path: '/patient/blood-glucose',
          query: { testType: 'heart_rate' },
        }"
        class="h-full"
      >
        <div
          class="flex flex-col rounded-2xl justify-between bg-ClearDay h-full px-5 py-3"
        >
          <div class="flex items-center justify-between">
            <p class="text-xs text-OlsoGrey font-semibold">Heart Rate</p>
            <img
              :src="heartRateIcon"
              class="p-2 bg-LavendaMist w-8 h-8 rounded-full"
              alt=""
            />
          </div>

          <div>
            <div v-if="!postData === null">
              <p class="text-lg md:text-2xl font-bold">
                {{ paginatedRecords[0]?.glucose_level }}
                <span class="text-xs font-semibold">bpm</span>
              </p>
              <p class="font-thin text-xs text-DavyGrey">
                {{ paginatedRecords[0]?.check_time }}
              </p>
            </div>
            <div v-else class="text-lg md:text-2xl font-bold">
              --- <span class="text-xs font-semibold">bpm</span>
            </div>
          </div>
        </div>
      </router-link>

      <!-- Blood Pressure -->
      <router-link
        :to="{
          path: '/patient/blood-glucose',
          query: { testType: 'blood_pressure' },
        }"
        class="h-full"
      >
        <div
          class="flex flex-col rounded-2xl justify-between bg-PattensBlue h-full px-5 py-3"
        >
          <div class="flex items-center justify-between">
            <p class="text-xs text-OlsoGrey font-semibold">Blood Pressure</p>
            <img
              :src="bloodPresureIcon"
              class="p-2 bg-LavendaMist w-8 h-8 rounded-full"
              alt=""
            />
          </div>

          <div>
            <div v-if="!postData === null">
              <p class="text-lg md:text-2xl font-bold">
                {{ paginatedRecords[0]?.glucose_level }}
                <span class="text-xs font-semibold">mmHg</span>
              </p>
              <p class="font-thin text-xs text-DavyGrey">
                {{ paginatedRecords[0]?.check_time }}
              </p>
            </div>
            <div v-else class="text-lg md:text-2xl font-bold">
              --- <span class="text-xs font-semibold">mmHg</span>
            </div>
          </div>
        </div>
      </router-link>

      <!-- Lung Capacity -->
      <router-link
        :to="{
          path: '/patient/blood-glucose',
          query: { testType: 'lung_capacity' },
        }"
        class="h-full"
      >
        <div
          class="flex flex-col rounded-2xl justify-between bg-ScotchMist h-full px-5 py-3"
        >
          <div class="flex items-center justify-between">
            <p class="text-xs text-OlsoGrey font-semibold">Lung Capacity</p>
            <img
              :src="lungCapacityIcon"
              class="p-2 bg-LavendaMist w-8 h-8 rounded-full"
              alt=""
            />
          </div>

          <div>
            <div v-if="!postData === null">
              <p class="text-lg md:text-2xl font-bold">
                {{ paginatedRecords[0]?.glucose_level }}
                <span class="text-xs font-semibold">TLC</span>
              </p>
              <p class="font-thin text-xs text-DavyGrey">
                {{ paginatedRecords[0]?.check_time }}
              </p>
            </div>
            <div v-else class="text-lg md:text-2xl font-bold">
              --- <span class="text-xs font-semibold">TLC</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { mapGetters } from "@/hooks/mapStore";
import { testTypes } from "@/utils/mockData/testTypes";
import glucoseIcon from "@/assets/icons/glucose-icon.svg";
import heartRateIcon from "@/assets/icons/hearth-rate-icon.svg";
import bloodPresureIcon from "@/assets/icons/blood-pressure-icon.svg";
import lungCapacityIcon from "@/assets/icons/lung-capacity-icon.svg";

const props = defineProps({
  glucoseData: Object, // The data fetched via useQuery in PatientDashboardView.vue
});

const { "user/getUserBloodGlucoseRecords": getUserBloodGlucoseRecords } =
  mapGetters();

const postData = computed(() => {
  return getUserBloodGlucoseRecords.value;
});

const paginatedRecords = computed(() => {
  if (postData.value && postData.value.glucose_records) {
    const formattedRecords = postData.value.glucose_records.map((record) => {
      const dateComponents = record.check_time.split(" ");
      const datePart = dateComponents[0].split("-").reverse().join("-");
      const timePart = dateComponents[1];
      const formattedTime = `${datePart} ${timePart}`;
      return { ...record, formatted_check_time: formattedTime };
    });
    const sortedRecords = formattedRecords.sort((a, b) => {
      const time_a = new Date(a.formatted_check_time).getTime();
      const time_b = new Date(b.formatted_check_time).getTime();
      return time_b - time_a;
    });
    return sortedRecords;
  }
  return [];
});

const high = ref(126);
const normal = ref(100);
// const average_high = ref(125);
</script>
