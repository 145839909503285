<template>
  <main
    class="mx-auto bg-white rounded-2xl shadow-lg px-4 py-6 min-h-screen flex flex-col lg:flex-row lg:space-x-10 p-4 md:p-10 space-y-6 xl:mx-10"
  >
    <div></div>
    <section
      class="w-full lg:w-[55%] bg-white border rounded-[20px] border-gray-200 p-6 lg:p-10 space-y-8"
    >
      <div class="flex items-center space-x-6">
        <div class="w-24 h-24 rounded-full border overflow-hidden">
          <img
            :src="data?.health_center_profile?.avatar_url || noAvatar"
            alt="Wellness Center"
            class="w-full h-full object-cover"
          />
        </div>
        <div>
          <h2 class="text-2xl font-bold">
            {{ data?.health_center_profile?.name || "Wellness Center" }}
          </h2>
          <p class="text-gray-600">
            {{ data?.health_center_profile?.email || "---" }}
          </p>
          <p class="text-sm text-gray-500">
            Operating Hours:
            {{ data?.health_center_profile?.hours || "9:00AM - 9:00PM" }}
          </p>
        </div>
      </div>

      <div
        v-if="isPending"
        class="w-6 h-6 mx-auto flex justify-center items-center"
      >
        <SpinnerIcon />
      </div>

      <template v-else>
        <div
          class="grid grid-cols-1 md:grid-cols-2 gap-4 bg-GhostWhite rounded-xl p-6"
        >
          <InputField
            label="Phone Number"
            :placeholder="data?.health_center_profile?.phone || '---'"
            disabled
          />
          <InputField
            label="Email Address"
            :placeholder="data?.health_center_profile?.email || '---'"
            disabled
          />
          <InputField
            label="Address"
            :placeholder="data?.health_center_profile?.address || '---'"
            disabled
          />
        </div>

        <div class="bg-GhostWhite rounded-xl p-6">
          <div
            class="flex justify-between items-center cursor-pointer"
            @click="toggleGeneralInfo"
          >
            <h3 class="text-lg font-bold">Emergency Service Contact</h3>
            <img
              :src="Arrow"
              :class="{ 'rotate-180': openGeneralInfo }"
              alt="toggle arrow"
            />
          </div>
          <div
            v-if="openGeneralInfo"
            class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4"
          >
            <InputField
              label="Contact Number (1)"
              :placeholder="
                data?.health_center_profile?.emergencyContact1 || '---'
              "
              disabled
            />
            <InputField
              label="Contact Number (2)"
              :placeholder="
                data?.health_center_profile?.emergencyContact2 || '---'
              "
              disabled
            />
          </div>
        </div>
      </template>
    </section>

    <section
      class="w-full lg:w-[45%] bg-white border rounded-[20px] border-gray-200 p-6 lg:p-10"
    >
      <h3 class="text-lg font-bold mb-4">Locate Us on the Map</h3>
      <p class="text-gray-600 mb-6">
        We can't wait to welcome you, use the map to plan your route.
      </p>
      <div class="h-64 w-full rounded-xl overflow-hidden">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.673913964194!2d7.434326314792503!3d9.0603900934726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104dd9abdf9d184f%3A0xa1ef2e60f0d9d3c8!2sGarki%20Area%2010!5e0!3m2!1sen!2sng!4v1638760123425!5m2!1sen!2sng"
          width="100%"
          height="250"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
  </main>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useQuery } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import InputField from "@/components/main/ui/InputField.vue";
import Arrow from "@/assets/icons/arrow-up-iocn.svg";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const route = useRoute();
const id = route.params.id;

const openGeneralInfo = ref(true);

const { "accountConnection/getLinkedCenterInfo": getLinkedCenterInfo } =
  mapActions();

const { data, isPending } = useQuery({
  queryKey: ["linked-accounts", id],
  queryFn: () => getLinkedCenterInfo({ id }),
});

const toggleGeneralInfo = () => {
  openGeneralInfo.value = !openGeneralInfo.value;
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
</style>
